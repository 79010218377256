export class ColorSampleOrderDto {
  id: number;
  name: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  colorIds: number[];
  internalRemarks?: string;
  status: 'requested' | 'rejected' | 'sent';
  _links?: LinksDto;

  constructor(dto: ColorSampleOrderDto) {
    Object.assign(this, dto);
  }
}

class LinksDto {
  download?: string;
  preview?: string;

  constructor(dto: LinksDto) {
    Object.assign(this, dto);
  }
}
