
import { defineComponent, PropType } from 'vue';

import Badge from '@components/Badge.vue';

type User = {
  active: boolean;
  emailConfirmed: boolean;
};

export default defineComponent({
  components: { Badge },
  props: {
    record: {
      type: Object as PropType<User>,
      required: true,
    },
  },
});
