import { Ref, ref } from 'vue';
import { ClosetCategoryDto, getClosetCategory } from '../services';

export default function useClosetCategory() {
  const result: Ref<ClosetCategoryDto | undefined> = ref();
  const active = ref(false);

  const run = async (id: number) => {
    active.value = true;

    result.value = await getClosetCategory(id);

    active.value = false;
  };

  return { active, result, run };
}
