import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-5" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "h4 text-gray-900 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_grid_col = _resolveComponent("grid-col")!
  const _component_FormPassword = _resolveComponent("FormPassword")!
  const _component_PasswordMeter = _resolveComponent("PasswordMeter")!
  const _component_FormItemWrapper = _resolveComponent("FormItemWrapper")!
  const _component_Button = _resolveComponent("Button")!
  const _component_grid_row = _resolveComponent("grid-row")!

  return (_openBlock(), _createBlock(_component_grid_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_grid_col, {
        classList: "col-12 col-lg-6 d-none d-lg-block bg-password-image",
        style: {"min-height":"535px"}
      }),
      _createVNode(_component_grid_col, { classList: "col-12 col-lg-6 my-auto" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('authentication.forgotPassword')), 1)
            ]),
            _createElementVNode("form", {
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
            }, [
              _createVNode(_component_FormItemWrapper, {
                label: _ctx.$t('changePassword.newPassword'),
                errors: _ctx.errors.newPassword
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormPassword, {
                    modelValue: _ctx.form.newPassword,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.newPassword) = $event)),
                    errors: _ctx.errors.newPassword
                  }, null, 8, ["modelValue", "errors"]),
                  (_ctx.form.newPassword)
                    ? (_openBlock(), _createBlock(_component_PasswordMeter, {
                        key: 0,
                        password: _ctx.form.newPassword
                      }, null, 8, ["password"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["label", "errors"]),
              _createVNode(_component_FormItemWrapper, {
                label: _ctx.$t('changePassword.repeatNewPassword'),
                errors: _ctx.errors.repeatNewPassword
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormPassword, {
                    modelValue: _ctx.form.repeatNewPassword,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.repeatNewPassword) = $event)),
                    errors: _ctx.errors.repeatNewPassword
                  }, null, 8, ["modelValue", "errors"])
                ]),
                _: 1
              }, 8, ["label", "errors"]),
              _createVNode(_component_Button, {
                block: "",
                type: "submit",
                disabled: _ctx.isResettingPassword
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('changePassword.confirm')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ], 32)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}