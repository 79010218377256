import { ArticleListDto } from './article-list-dto';

export class ClosetStructureDto {
  id: number;
  number: string;
  name: string;
  extraPrice: number | undefined;
  imageId: number;
  imageUrl?: string;
  articleList: ArticleListDto[];
  doorArticleList: ArticleListDto[];
  closetCategoryIds: number[];

  constructor({ articleList, doorArticleList, ...rest }: ClosetStructureDto) {
    this.articleList = articleList.map((item) => new ArticleListDto(item));
    this.doorArticleList = doorArticleList.map((item) => new ArticleListDto(item));
    Object.assign(this, rest);
  }
}
