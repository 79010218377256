
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import Button from '@components/Button.vue';
import FormInput from '@components/form/FormInput.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';

import { DateFormat, dateToString } from '@utils/date.util';

import { Role } from '@modules/kernel/enums';
import { useForm } from '@modules/kernel/hooks';
import { getCurrentRole } from '@modules/kernel/services';

export default defineComponent({
  components: { FormItemWrapper, FormInput, Button },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isAccount: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const { form, errors, validate } = useForm({
      name: {
        type: String,
        required: true,
        default: props.user.name,
      },
      email: {
        type: String,
        required: true,
        default: props.user.email,
        email: true,
      },
      phone: {
        type: String,
        required: false,
        default: props.user.phone,
      },
    });

    const isRoot = computed(() => getCurrentRole() === Role.Root);

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      emit('save', form);
    };

    const translatedEmailConfirmed = computed(() => t(props.user.emailConfirmed ? 'global.yes' : 'global.no'));
    const formattedLastLogin = computed(() =>
      props.user.lastLogin ? dateToString(props.user.lastLogin, DateFormat.Medium) : t('user.lastLogin.unknown')
    );

    return {
      errors,
      form,
      formattedLastLogin,
      isRoot,
      translatedEmailConfirmed,

      submitForm,
    };
  },
});
