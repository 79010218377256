
import { computed, defineComponent, PropType } from 'vue';

export type Option = {
  value: number | string;
  label?: string;
  translationKey?: string;
  readonly?: boolean;
};

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<string[] | number[]>,
      default: () => [],
    },
    errors: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<Option[]>,
      default: () => [],
    },
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const filteredErrors = computed(() => props.errors.filter((error) => error));

    const handleChange = (event: any) => {
      let newValue = [...props.modelValue];
      if (event.target.checked) {
        const selectedOption = props.options.find((option) => option.value == event.target.value);
        if (selectedOption) {
          newValue.push(selectedOption.value);
        }
      } else {
        newValue = newValue.filter((mv) => mv != event.target.value);
      }

      emit('update:modelValue', newValue || []);
      emit('change', newValue || []);
    };

    const isOptionSelected = (value: any) => props.modelValue.some((mv) => mv === value);

    return { filteredErrors, handleChange, isOptionSelected };
  },
});
