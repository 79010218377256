import { Language } from '@/i18n';

export class CurrentUserDto {
  id: number;
  name: string;
  email: string;
  phone: string;
  imageUrl: string;
  language: Language;

  constructor(dto: CurrentUserDto) {
    Object.assign(this, dto);
  }
}
