import { Ref, ref } from 'vue';
import { getPages, PageDto } from '../services';

const result: Ref<PageDto[]> = ref([]);
const active = ref(false);

export default function usePages() {
  const run = async () => {
    active.value = true;

    result.value = await getPages();

    active.value = false;
  };

  return { result, active, run };
}
