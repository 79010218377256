
import { computed, ComputedRef, defineComponent } from 'vue';

import PageHeader from '@components/layout/PageHeader.vue';

import Tabs, { Tab } from '@components/tabs/Tabs.vue';

import DynamicWebsiteTextsWrapper from './components/DynamicWebsiteTextsWrapper.vue';
import usePages from './hooks/use-pages';
import { PageDto } from './services';

export default defineComponent({
  components: { PageHeader, Tabs },
  setup() {
    const { result: pages, active: isLoadingPages, run: runGetPages } = usePages();
    runGetPages();

    const tabs: ComputedRef<Tab[]> = computed(() =>
      pages.value.map((page: PageDto) => ({
        key: page.key,
        label: page.label,
        component: getComponentForPage(page),
      }))
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getComponentForPage = (page: PageDto) => {
      // if there is any page that needs a custom component, insert an if-case here
      return DynamicWebsiteTextsWrapper;
    };

    return { tabs, isLoadingPages };
  },
});
