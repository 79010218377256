import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13fd8c10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["id", "type", "value", "onInput", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actualLength, (index) => {
      return (_openBlock(), _createElementBlock("input", {
        key: index,
        id: `${index}`,
        type: _ctx.inputType,
        value: _ctx.modelValueParts[index - 1] ?? '',
        class: _normalizeClass(["mx-1 text-center form-control", { 'is-invalid': _ctx.filteredErrors.length }]),
        onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
        onInput: ($event: any) => (_ctx.handleInput($event, index)),
        onKeydown: _withKeys(($event: any) => (_ctx.handleKeydown($event, index)), ["delete"]),
        style: {"padding":"0"}
      }, null, 42, _hoisted_2))
    }), 128))
  ]))
}