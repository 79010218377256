import { createI18n } from 'vue-i18n';

import baseEN from './locales/base-en.json';
import baseNL from './locales/base-nl.json';
import specificEN from './locales/en.json';
import specificNL from './locales/nl.json';

const en = { ...baseEN, ...specificEN };
const nl = { ...baseNL, ...specificNL };

export enum Language {
  English = 'en',
  Dutch = 'nl',
}

export const setupNewInstance = (language: Language) => {
  return createI18n({
    locale: language,
    messages: { en, nl },
    fallbackLocale: Language.Dutch,
    silentTranslationWarn: true,
  });
};

const i18n = setupNewInstance(Language.Dutch);
export default i18n;
