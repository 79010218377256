import api from '@/custom/api';
import { PageDto, TextDto } from './types';

export async function getPages(): Promise<PageDto[]> {
  const response = await api.get('/website-texts/all-pages');
  return response.data.map((item: any) => new PageDto(item));
}

export async function getTexts(page: string): Promise<TextDto[]> {
  const response = await api.get(`/website-texts/${page}`);
  return response.data.map((item: any) => new TextDto(item));
}

export async function saveTexts(page: string, form: Record<string, string>): Promise<Record<string, string>[]> {
  const response = await api.patch(`/website-texts/${page}`, form);
  return response?.data;
}
