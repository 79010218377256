export function snakeToCamelCase(value: string): string {
  const camel = value.toLowerCase().replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''));

  if (value.substring(0, 1) === '_') {
    return `_${camel.charAt(0).toLowerCase()}${camel.slice(1)}`;
  }
  return camel;
}

export function camelToSnakeCase(value: string): string {
  return value.replace(/[A-Z]/g, (group) => `_${group.toLowerCase()}`);
}

export function kebabToCamelCase(value: string): string {
  return value.toLowerCase().replace(/([-][a-z])/g, (group) => group.toUpperCase().replace('-', ''));
}

export function matchesRegex(regex: RegExp, value: string): boolean {
  return regex.test(value);
}

export function brToNewLine(value: string): string {
  return value.replace(/<br\s*\/?>/gm, '\n');
}

export function stringToBoolean(value: boolean | number | string): boolean {
  const normalized = value.toString().toLowerCase();
  return normalized === 'true' || normalized === '1';
}
