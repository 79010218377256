
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    errors: {
      type: Array as PropType<any>,
      required: true,
    },
  },
});
