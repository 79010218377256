import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormPassword = _resolveComponent("FormPassword")!
  const _component_FormItemWrapper = _resolveComponent("FormItemWrapper")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: "md",
    "is-form-touched": _ctx.isFormTouched,
    onClose: _ctx.closeModal
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('global.areYouSure?')), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("p", {
        innerHTML: _ctx.$t(_ctx.bodyTranslationKey)
      }, null, 8, _hoisted_1),
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)), ["prevent"]))
      }, [
        (_ctx.askPassword)
          ? (_openBlock(), _createBlock(_component_FormItemWrapper, {
              key: 0,
              errors: _ctx.errors.password
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormPassword, {
                  modelValue: _ctx.form.password,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.password) = $event)),
                  placeholder: _ctx.$t('global.password'),
                  errors: _ctx.errors.password,
                  showToggle: ""
                }, null, 8, ["modelValue", "placeholder", "errors"])
              ]),
              _: 1
            }, 8, ["errors"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_Button, null, null, 512), [
          [_vShow, false]
        ])
      ], 32)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        color: "secondary",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_Button, {
        color: _ctx.color,
        onClick: _ctx.confirm,
        disabled: _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(_ctx.buttonTranslationKey)), 1)
        ]),
        _: 1
      }, 8, ["color", "onClick", "disabled"])
    ]),
    _: 1
  }, 8, ["is-form-touched", "onClose"]))
}