import { Language } from '@/i18n';

export class GeneralSettingsDto {
  language: Language;
  notificationEmail: string;

  constructor(dto: GeneralSettingsDto) {
    Object.assign(this, dto);
  }
}
