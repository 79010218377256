export class UpdateCustomerDto {
  name: string;
  companyName?: string;
  companyVat?: string;
  phone?: string;
  email: string;
  street?: string;
  postalCode?: string;
  city?: string;

  constructor(dto: UpdateCustomerDto) {
    this.name = dto.name;
    this.companyName = dto.companyName;
    this.companyVat = dto.companyVat;
    this.phone = dto.phone;
    this.email = dto.email;
    this.street = dto.street;
    this.postalCode = dto.postalCode;
    this.city = dto.city;
  }
}
