
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';

import Button from '@components/Button.vue';
import FormInput from '@components/form/FormInput.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';

import { useForm } from '@modules/kernel/hooks';
import { createAsyncProcess } from '@modules/kernel/utils';

import { forgotPassword } from './services';

export default defineComponent({
  components: { GridRow, GridCol, FormItemWrapper, FormInput, Button },
  setup() {
    const toast = useToast();
    const { t } = useI18n();

    const { active: isResettingPassword, run: runForgotPassword } = createAsyncProcess(forgotPassword);

    const { form, errors, validate, resetForm } = useForm({
      email: {
        type: String,
        required: true,
        email: true,
      },
    });

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      try {
        await runForgotPassword(form.email);
        toast.success(t('authentication.resetPasswordEmailSent'));
        resetForm();
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    return { form, errors, isResettingPassword, submitForm };
  },
});
