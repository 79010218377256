import { Language } from '@/i18n';

export class UpdateRootSettingsDto {
  defaultLanguage: Language;
  infoLogRetentionPeriod: number;
  warningLogRetentionPeriod: number;
  errorLogRetentionPeriod: number;

  constructor(dto: UpdateRootSettingsDto) {
    this.defaultLanguage = dto.defaultLanguage;
    this.infoLogRetentionPeriod = dto.infoLogRetentionPeriod;
    this.warningLogRetentionPeriod = dto.warningLogRetentionPeriod;
    this.errorLogRetentionPeriod = dto.errorLogRetentionPeriod;
  }
}
