export function isObject(value: any) {
  return value === Object(value) && !Array.isArray(value) && typeof value !== 'function' && !(value instanceof Date);
}

export function mapObjectPropNames(value: any, mapper: (input: string) => string): any {
  if (Array.isArray(value)) {
    return value.map((item) => mapObjectPropNames(item, mapper));
  }

  if (isObject(value)) {
    const newValue: any = {};

    Object.keys(value).forEach((key) => {
      newValue[mapper(key)] = mapObjectPropNames(value[key], mapper);
    });

    return newValue;
  }

  return value;
}

export function deepCopy<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}
