
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import Multiselect from '@vueform/multiselect';

export type Option = {
  value: number | string | boolean;
  label?: string;
  translationKey?: string;
  readonly?: boolean;
};

export default defineComponent({
  components: { Multiselect },
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },
    errors: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<Option[]>,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const filteredErrors = computed(() => props.errors.filter((error) => error));

    const mappedOptions = computed(() =>
      props.options.map((option) => ({
        value: option.value,
        label: option.translationKey ? t(option.translationKey) : option.label ?? '',
        disabled: option.readonly ?? false,
      }))
    );

    const handleChange = (value: any) => {
      emit('update:modelValue', value);
      emit('change', value);
    };

    return {
      filteredErrors,
      mappedOptions,

      handleChange,
    };
  },
});
