
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    errors: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
  },
  emits: ['update:modelValue', 'input'],
  setup(props, { emit }) {
    const filteredErrors = computed(() => props.errors.filter((error) => error));
    const handleInput = (event: any) => {
      emit('update:modelValue', event.target.value || null);
      emit('input', event.target.value || null);
    };

    return { filteredErrors, handleInput };
  },
});
