
import { computed, defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

import { RouteKeys } from '@/router';

import ContentCard from '@components/layout/ContentCard.vue';
import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';
import PageHeader from '@components/layout/PageHeader.vue';

import BackButton from '@components/BackButton.vue';
import Button from '@components/Button.vue';
import LoadingSpinner from '@components/LoadingSpinner.vue';

import ConfirmDeleteModal from '@modules/kernel/components/ConfirmDeleteModal.vue';
import { Role } from '@modules/kernel/enums';
import { useModal } from '@modules/kernel/hooks';
import { getCurrentRole } from '@modules/kernel/services';
import { useCurrentUserStore } from '@modules/kernel/stores';
import { createAsyncProcess } from '@modules/kernel/utils';

import UserAvatar from './components/UserAvatar.vue';
import UserDetails from './components/UserDetails.vue';
import useUser from './hooks/use-user';
import { deleteUser, impersonateUser, saveUser, UpdateUserDto } from './services';

export default defineComponent({
  components: {
    PageHeader,
    GridRow,
    GridCol,
    ContentCard,
    UserDetails,
    UserAvatar,
    LoadingSpinner,
    BackButton,
    Button,
    ConfirmDeleteModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const { t } = useI18n();

    const { active: isLoadingUser, result: user, run: runGetUser } = useUser();
    const { active: isSavingUser, run: runSaveUser } = createAsyncProcess(saveUser);
    const { active: isDeletingUser, run: runDeleteUser } = createAsyncProcess(deleteUser);
    const { active: isImpersonatingUser, run: runImpersonateUser } = createAsyncProcess(impersonateUser);

    const store = useCurrentUserStore();
    const { currentUser } = storeToRefs(store);

    const id = +route.params.id;

    /**
     * LOAD DATA
     */
    const getUser = async () => {
      try {
        await runGetUser(id);
      } catch (error: any) {
        toast.error(error.message);
        return;
      }
    };
    getUser();

    /**
     * UPDATE USER
     */
    const submitForm = async (value: UpdateUserDto) => {
      try {
        await runSaveUser(id, value);
        toast.success(t('global.changesSaved'));
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    /**
     * IMPERSONATE
     */
    const submitImpersonate = async () => {
      try {
        await runImpersonateUser(id);
        window.open(router.resolve({ name: RouteKeys.Dashboard }).href, '_self');
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    /**
     * DELETE
     */
    const {
      visible: isConfirmDeleteModalVisible,
      show: showConfirmDeleteModal,
      hide: hideConfirmDeleteModal,
    } = useModal();

    const submitDelete = async (password: string) => {
      try {
        await runDeleteUser(id, password);
        toast.success(t('global.successfullyDeleted'));
        router.push({ name: RouteKeys.UserOverview });
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    const isRoot = computed(() => getCurrentRole() === Role.Root);
    const canDeleteUser = computed(() => currentUser.value!.id !== id);

    return {
      canDeleteUser,
      isConfirmDeleteModalVisible,
      isDeletingUser,
      isImpersonatingUser,
      isLoadingUser,
      isRoot,
      isSavingUser,
      user,

      hideConfirmDeleteModal,
      showConfirmDeleteModal,
      submitDelete,
      submitForm,
      submitImpersonate,

      RouteKeys,
    };
  },
});
