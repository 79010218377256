import { Ref, ref } from 'vue';
import { getEmptyPageMetadata, PageMetadata, PaginationFilters } from '@modules/kernel/types';
import { getColors } from '../services';
import { Color, mapDtoToColor } from '../types';

const result: Ref<Color[]> = ref([]);
const metadata: Ref<PageMetadata> = ref(getEmptyPageMetadata());
const active = ref(false);

export default function useColors() {
  const run = async (search?: string, paginationFilters?: PaginationFilters) => {
    active.value = true;

    const response = await getColors(search, paginationFilters);
    result.value = response.items.map((item) => mapDtoToColor(item));
    metadata.value = response.page;

    active.value = false;
  };

  return { result, metadata, active, run };
}
