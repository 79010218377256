import { ArticlePartListDto } from './article-part-list-dto';

export class CreateArticleDto {
  number: string;
  name: string;
  extraPrice: number;
  partList: ArticlePartListDto[];

  constructor(dto: CreateArticleDto) {
    this.number = dto.number;
    this.name = dto.name;
    this.extraPrice = dto.extraPrice;
    this.partList = dto.partList.map((item) => new ArticlePartListDto(item));
  }
}
