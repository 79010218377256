import { Ref, ref } from 'vue';
import { ArticleDto, getArticle } from '../services';

export default function useArticle() {
  const result: Ref<ArticleDto | undefined> = ref();
  const active = ref(false);

  const run = async (id: number) => {
    active.value = true;

    result.value = await getArticle(id);

    active.value = false;
  };

  return { active, result, run };
}
