import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { OrderFilters } from '../types';
import { OrderDto, OrderItemDto } from './types';

export async function getOrders(
  search?: string,
  paginationFilters?: PaginationFilters,
  filters?: OrderFilters
): Promise<PaginatedResponse<OrderDto>> {
  const response = await api.get('/orders', { params: { ...paginationFilters, ...filters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new OrderDto(item)),
  };
}

export async function getOrder(id: number): Promise<OrderDto> {
  const response = await api.get(`/orders/${id}`);
  return new OrderDto(response.data);
}

export async function getOrderItems(orderId: number): Promise<OrderItemDto[]> {
  const response = await api.get(`/orders/${orderId}/items`);
  return response.data.map((item: any) => new OrderDto(item));
}
