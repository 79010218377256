
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import PasswordMeter from 'vue-simple-password-meter';
import { useToast } from 'vue-toastification';

import ContentCard from '@components/layout/ContentCard.vue';
import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';
import PageHeader from '@components/layout/PageHeader.vue';

import Button from '@components/Button.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import FormPassword from '@components/form/FormPassword.vue';

import { useForm } from '@modules/kernel/hooks';
import { createAsyncProcess } from '@modules/kernel/utils';

import { changePassword } from './services';

export default defineComponent({
  components: {
    PageHeader,
    GridRow,
    GridCol,
    ContentCard,
    FormItemWrapper,
    FormPassword,
    Button,
    PasswordMeter,
  },
  setup() {
    const toast = useToast();
    const { t } = useI18n();

    const { active: isChangingPassword, run: runChangePassword } = createAsyncProcess(changePassword);

    const { form, errors, validate, resetForm } = useForm({
      oldPassword: {
        type: String,
        required: true,
      },
      newPassword: {
        type: String,
        required: true,
        minLength: 6,
      },
      repeatNewPassword: {
        type: String,
        required: true,
        sameAs: { key: 'newPassword', label: t('changePassword.newPassword') },
      },
    });

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      try {
        await runChangePassword(form);
        toast.success(t('global.changesSaved'));
        resetForm();
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    return { errors, form, isChangingPassword, submitForm };
  },
});
