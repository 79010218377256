
import { computed, defineComponent } from 'vue';

export default defineComponent({
  setup(_, { slots }) {
    const hasActions = computed(() => !!slots.actions);

    return { hasActions };
  },
});
