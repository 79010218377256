
import { defineComponent, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

import { RouteKeys } from '@/router';

import LoadingSpinner from '@components/LoadingSpinner.vue';

import { confirmEmail } from './services/auth-service';

export default defineComponent({
  components: { LoadingSpinner },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { t } = useI18n();

    onMounted(async () => {
      const token = route.query?.token as string;

      if (!token) {
        router.push({ name: RouteKeys.Login });
      }

      try {
        const response = await confirmEmail(token);
        toast.success(t('user.emailSuccessfullyConfirmed'));
        router.push({ name: response ? RouteKeys.Dashboard : RouteKeys.Login });
      } catch (error: any) {
        router.push({ name: RouteKeys.Login });
      }
    });
  },
});
