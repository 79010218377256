import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { ColorCategoryDto, CreateColorCategoryDto, UpdateColorCategoryDto } from './types';

export async function getColorCategories(
  search?: string,
  paginationFilters?: PaginationFilters
): Promise<PaginatedResponse<ColorCategoryDto>> {
  const response = await api.get('/color-categories', { params: { ...paginationFilters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new ColorCategoryDto(item)),
  };
}

export async function getColorCategory(id: number): Promise<ColorCategoryDto> {
  const response = await api.get(`/color-categories/${id}`);
  return new ColorCategoryDto(response.data);
}

export async function saveColorCategory(id: number, form: UpdateColorCategoryDto): Promise<void> {
  const response = await api.put(`/color-categories/${id}`, new UpdateColorCategoryDto(form));
  return response?.data;
}

export async function createColorCategory(form: CreateColorCategoryDto): Promise<void> {
  const response = await api.post('/color-categories', new CreateColorCategoryDto(form));
  return response?.data;
}

export async function deleteColorCategory(id: number): Promise<void> {
  const response = await api.delete(`/color-categories/${id}`);
  return response?.data;
}
