import { defineStore } from 'pinia';

type Store = { isSidebarVisible: boolean };

export const useSidebarStore = defineStore('sidebar', {
  state: () => {
    return { isSidebarVisible: true } as Store;
  },
  actions: {
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    setSidebarVisible(visible: boolean) {
      this.isSidebarVisible = visible;
    },
  },
});
