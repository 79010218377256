
import { defineComponent } from 'vue';

import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';

import Footer from './components/Footer.vue';

export default defineComponent({
  components: { GridRow, GridCol, Footer },
});
