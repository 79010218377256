import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormTextarea = _resolveComponent("FormTextarea")!
  const _component_FormItemWrapper = _resolveComponent("FormItemWrapper")!
  const _component_grid_col = _resolveComponent("grid-col")!
  const _component_Button = _resolveComponent("Button")!
  const _component_grid_row = _resolveComponent("grid-row")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
  }, [
    _createVNode(_component_grid_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_grid_col, { classList: "col-12" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.texts, (text) => {
              return (_openBlock(), _createBlock(_component_FormItemWrapper, {
                key: text.key,
                label: text.label,
                errors: _ctx.errors[text.key]
              }, {
                default: _withCtx(() => [
                  (text.amountOfRules === 1)
                    ? (_openBlock(), _createBlock(_component_FormInput, {
                        key: 0,
                        modelValue: _ctx.form[text.key],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.form[text.key]) = $event),
                        placeholder: text.label,
                        type: "text"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
                    : (_openBlock(), _createBlock(_component_FormTextarea, {
                        key: 1,
                        modelValue: _ctx.form[text.key],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.form[text.key]) = $event),
                        placeholder: text.label,
                        rows: text.amountOfRules
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "rows"]))
                ]),
                _: 2
              }, 1032, ["label", "errors"]))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_grid_col, { classList: "col-12 mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              type: "submit",
              disabled: _ctx.isSavingTexts
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('global.save')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 32))
}