
import { computed, defineComponent, PropType } from 'vue';
import moment from 'moment';

import Button from '@components/Button.vue';

import { DateFormat, dateToString } from '@utils/date.util';

export default defineComponent({
  components: { Button },
  props: {
    modelValue: {
      type: [Date],
      required: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    datetime: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'input'],
  setup(props, { emit }) {
    const filteredErrors = computed(() => props.errors.filter((error) => error));

    const handleInput = (event: any) => {
      emitDate(event.target.value);
    };

    const subtract = () => emitDate(moment(props.modelValue).subtract(1, 'day').toDate());
    const add = () => emitDate(moment(props.modelValue).add(1, 'day').toDate());

    const emitDate = (date: any) => {
      const formattedDate = date ? new Date(date) : date;
      emit('update:modelValue', formattedDate);
      emit('input', formattedDate);
    };

    const type = computed(() => (props.datetime ? 'datetime-local' : 'date'));
    const formattedModelValue = computed(() =>
      props.modelValue ? dateToString(props.modelValue, props.datetime ? DateFormat.Iso : DateFormat.IsoShort) : ''
    );

    return {
      filteredErrors,
      formattedModelValue,
      type,

      add,
      handleInput,
      subtract,
    };
  },
});
