import moment from 'moment';

export enum DateFormat {
  Long = 'DD/MM/YYYY HH:mm:ss',
  LongWithDashes = 'DD-MM-YYYY HH:mm:ss',
  Medium = 'DD/MM/YYYY HH:mm',
  Short = 'DD/MM/YYYY',
  ShortWithShortYear = 'DD/MM/YY',
  ShortWithDay = 'dd DD/MM/YYYY',
  ShortWithDashes = 'DD-MM-YYYY',
  Year = 'YYYY',
  Iso = 'YYYY-MM-DD[T]HH:mm:ss',
  IsoWithoutT = 'YYYY-MM-DD HH:mm:ss',
  IsoShort = 'YYYY-MM-DD',
  Time = 'HH:mm',
  MonthYear = 'MMMM YYYY',
  WeekdayFullWithDayOfMonth = 'dddd DD/MM',
  WeekdayLongWithDayOfMonth = 'ddd, DD/MM',
  WeekdayWithDayOfMonth = 'dd DD/MM',
  WeekdayWithDayOfMonthAndTime = 'dd DD/MM, HH:mm',
  DayMonth = 'DD/MM',
  FullDate = 'dd DD MMMM YYYY HH:mm',
  YearMonth = 'YYYY-MM',
  YearWeek = 'GGGG-WW',
  FullDay = 'dddd',
}

export function dateToString(value: any, format: DateFormat | string = DateFormat.Short): string {
  if (!value) return '';

  const momentDate = moment(value);

  if (!momentDate.isValid()) return value;
  return momentDate.format(format);
}

export function isToday(date: Date) {
  const today = new Date();

  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
}

export function isValidDate(value: any) {
  if (!moment(value).isValid()) {
    return false;
  }

  const date = new Date(value);
  if (date.getFullYear().toString().length !== 4) {
    return false;
  }

  return true;
}

export function isInPast(date: Date) {
  return moment(date).isBefore(new Date(), 'day');
}

export function parseDate(value: any) {
  if (!value) return undefined;
  return new Date(value.toString().replace(/-/g, '/'));
}
