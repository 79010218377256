import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f13d348"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = ["type", "value", "placeholder", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: _ctx.type,
      value: _ctx.modelValue,
      class: _normalizeClass(["form-control", { 'is-invalid': _ctx.filteredErrors.length }]),
      placeholder: _ctx.placeholder,
      readonly: _ctx.readonly,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 42, _hoisted_2),
    (_ctx.showToggleIcon)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePassword && _ctx.togglePassword(...args)))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["fa", _ctx.icon]),
            "aria-hidden": "true"
          }, null, 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}