import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { ArticleDto, CreateArticleDto, UpdateArticleDto } from './types';

export async function getArticles(
  search?: string,
  paginationFilters?: PaginationFilters
): Promise<PaginatedResponse<ArticleDto>> {
  const response = await api.get('/articles', { params: { ...paginationFilters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new ArticleDto(item)),
  };
}

export async function getArticle(id: number): Promise<ArticleDto> {
  const response = await api.get(`/articles/${id}`);
  return new ArticleDto(response.data);
}

export async function saveArticle(id: number, form: UpdateArticleDto): Promise<void> {
  const response = await api.put(`/articles/${id}`, new UpdateArticleDto(form));
  return response?.data;
}

export async function createArticle(form: CreateArticleDto): Promise<void> {
  const response = await api.post('/articles', new CreateArticleDto(form));
  return response?.data;
}

export async function deleteArticle(id: number): Promise<void> {
  const response = await api.delete(`/articles/${id}`);
  return response?.data;
}
