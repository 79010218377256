import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43744a16"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "nav-item collapsed"
}
const _hoisted_2 = { class: "collapse show" }
const _hoisted_3 = { class: "bg-white py-2 collapse-inner rounded" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.filteredChildren.length)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createElementVNode("a", {
          class: _normalizeClass(["nav-link", { collapsed: !_ctx.isNavDropdownVisible }]),
          href: "#",
          "data-toggle": "collapse",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleNavDropdown && _ctx.toggleNavDropdown(...args)))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["fas fa-fw", _ctx.iconClass])
          }, null, 2),
          _createElementVNode("span", null, _toDisplayString(_ctx.formattedTitle), 1)
        ], 2),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredChildren, (child) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: child.link,
                class: "collapse-item",
                to: { name: child.link }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatTitle(_ctx.$t(child.key))), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ])
        ], 512), [
          [_vShow, _ctx.isNavDropdownVisible]
        ])
      ]))
    : _createCommentVNode("", true)
}