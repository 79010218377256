export class CreateClosetGripDto {
  number: string;
  name: string;
  price: number;
  imageId: number;

  constructor(dto: CreateClosetGripDto) {
    this.number = dto.number;
    this.name = dto.name;
    this.price = dto.price;
    this.imageId = dto.imageId;
  }
}
