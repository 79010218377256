import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormItemWrapper = _resolveComponent("FormItemWrapper")!
  const _component_FormDropdown = _resolveComponent("FormDropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: "md",
    loading: _ctx.isFetchingRoles,
    "is-form-touched": _ctx.isFormTouched,
    onClose: _ctx.closeModal
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('user.add')), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
      }, [
        _createVNode(_component_FormItemWrapper, {
          label: _ctx.$t('global.name'),
          errors: _ctx.errors.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormInput, {
              modelValue: _ctx.form.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
              errors: _ctx.errors.name,
              type: "text"
            }, null, 8, ["modelValue", "errors"])
          ]),
          _: 1
        }, 8, ["label", "errors"]),
        _createVNode(_component_FormItemWrapper, {
          label: _ctx.$t('global.email'),
          errors: _ctx.errors.email
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormInput, {
              modelValue: _ctx.form.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.email) = $event)),
              errors: _ctx.errors.email,
              type: "email"
            }, null, 8, ["modelValue", "errors"])
          ]),
          _: 1
        }, 8, ["label", "errors"]),
        _createVNode(_component_FormItemWrapper, {
          label: _ctx.$t('global.phone'),
          errors: _ctx.errors.phone
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormInput, {
              modelValue: _ctx.form.phone,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.phone) = $event)),
              errors: _ctx.errors.phone,
              type: "tel"
            }, null, 8, ["modelValue", "errors"])
          ]),
          _: 1
        }, 8, ["label", "errors"]),
        (_ctx.roleOptions.length > 1)
          ? (_openBlock(), _createBlock(_component_FormItemWrapper, {
              key: 0,
              label: _ctx.$t('user.role'),
              errors: _ctx.errors.role
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormDropdown, {
                  modelValue: _ctx.form.role,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.role) = $event)),
                  options: _ctx.roleOptions,
                  errors: _ctx.errors.role
                }, null, 8, ["modelValue", "options", "errors"])
              ]),
              _: 1
            }, 8, ["label", "errors"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_Button, null, null, 512), [
          [_vShow, false]
        ])
      ], 32)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        color: "secondary",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_Button, {
        color: "primary",
        disabled: _ctx.isFetchingRoles || _ctx.isAddingUser,
        onClick: _ctx.submitForm
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('global.add')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["loading", "is-form-touched", "onClose"]))
}