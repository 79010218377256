
import { computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    errors: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showToggle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'input'],
  setup(props, { emit }) {
    const filteredErrors = computed(() => props.errors.filter((error) => error));
    const handleInput = (event: any) => {
      hasValue.value = event.target.value.length > 0;

      emit('update:modelValue', event.target.value || null);
      emit('input', event.target.value || null);
    };

    const hasValue = ref(`${props.modelValue}`.length > 0);
    const showToggleIcon = computed(() => props.showToggle && hasValue.value);

    const showPassword = ref(false);
    const togglePassword = () => (showPassword.value = !showPassword.value);

    const type = computed(() => (showPassword.value ? 'text' : 'password'));
    const icon = computed(() => (showPassword.value ? 'fa-eye-slash' : 'fa-eye'));

    return { filteredErrors, icon, showPassword, showToggleIcon, type, handleInput, togglePassword };
  },
});
