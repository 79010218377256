import { Ref, ref } from 'vue';
import { ClosetCategoryDto, getClosetCategories } from '../services';

const result: Ref<ClosetCategoryDto[]> = ref([]);
const active = ref(false);

export default function useClosetCategories() {
  const run = async () => {
    active.value = true;

    result.value = await getClosetCategories();

    active.value = false;
  };

  return { result, active, run };
}
