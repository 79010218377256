import api from '@/custom/api';
import i18n from '@/i18n';
import { CurrentUserDto } from './types';

export async function getCurrentUser(): Promise<CurrentUserDto> {
  const response = await api.get('/users/me');

  if (response.data?.language) {
    i18n.global.locale = response.data.language;
  }

  return new CurrentUserDto(response.data);
}
