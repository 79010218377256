import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Table = _resolveComponent("Table")!
  const _component_ContentCard = _resolveComponent("ContentCard")!
  const _component_grid_col = _resolveComponent("grid-col")!
  const _component_grid_row = _resolveComponent("grid-row")!
  const _component_JsonObject = _resolveComponent("JsonObject")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageHeader, null, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('global.logs')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_grid_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_grid_col, { classList: "col-12 mb-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_ContentCard, null, {
              default: _withCtx(() => [
                _createVNode(_component_Table, {
                  columns: _ctx.columns,
                  records: _ctx.logs,
                  loading: _ctx.isLoadingLogs,
                  "server-search": "",
                  "update-url": "",
                  onShowDetails: _ctx.showLogModal,
                  onOnSearch: _ctx.getLogs
                }, null, 8, ["columns", "records", "loading", "onShowDetails", "onOnSearch"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.isLogModalVisible)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          size: "xxl",
          loading: !_ctx.selectedLog,
          onClose: _ctx.hideLogModal
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('logs.details')), 1)
          ]),
          body: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.logModalbody), 1),
            _createVNode(_component_grid_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_grid_col, { classList: "col-12 col-lg-6" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('logs.objectBefore')) + " ", 1),
                    _createVNode(_component_JsonObject, {
                      content: _ctx.selectedLog?.objectBefore
                    }, null, 8, ["content"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_grid_col, { classList: "col-12 col-lg-6" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('logs.objectAfter')) + " ", 1),
                    _createVNode(_component_JsonObject, {
                      content: _ctx.selectedLog?.objectAfter
                    }, null, 8, ["content"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              color: "secondary",
              onClick: _ctx.hideLogModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('global.close')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["loading", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}