
import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';

import TabButton from './TabButton.vue';
import TabContent from './TabContent.vue';

export type Tab = {
  key: string;
  label?: string;
  translationKey?: string;
  component: any;
  visible?: () => boolean;
};

export default defineComponent({
  components: { GridRow, GridCol, TabButton, TabContent },
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();

    const visibleTabs = computed(() => props.tabs.filter((tab) => (tab.visible ? tab.visible() ?? true : true)));

    const firstTab = computed(() => props.tabs[0] as Tab);
    const tabFromQuery = computed(() => visibleTabs.value.find((tab) => tab.key === (route.query.tab as string)));

    const activeTab: Ref<undefined | string> = ref(undefined);

    const calculteActiveTab = () => {
      if (activeTab.value) return;
      activeTab.value = tabFromQuery.value?.key ?? firstTab.value?.key;
    };

    calculteActiveTab();
    watch(() => props.tabs, calculteActiveTab);

    const setActiveTab = (key: string) => {
      activeTab.value = key;
    };

    return { activeTab, visibleTabs, setActiveTab };
  },
});
