import { computed, Ref, ref, watch } from 'vue';

export function useCountdown(options: { callback?: () => void }) {
  // DEFAULT PROPS
  const running = computed(() => interval.value !== undefined);
  const timer = ref(0);

  // INTERVAL
  const interval: Ref<number | undefined> = ref(undefined);
  const startInterval = () => {
    interval.value = setInterval(() => {
      if (timer.value <= 1) {
        stop();
        return;
      }

      timer.value -= 1;
    }, 1000);
  };
  const resetInterval = () => {
    if (!interval.value) {
      return;
    }

    clearInterval(interval.value);
    interval.value = undefined;
  };

  // EXPOSED FUNCTIONS
  const start = (seconds?: number) => {
    if (seconds) {
      set(seconds);
    }

    startInterval();
  };
  const stop = () => {
    resetInterval();
    timer.value = 0;
  };
  const pause = () => {
    resetInterval();
  };
  const set = (seconds: number) => {
    timer.value = seconds;
  };

  // CALLBACK HANDLING
  watch(timer, () => {
    if (timer.value === 0 && options?.callback) {
      options.callback();
    }
  });

  return { running, timer, start, stop, pause, set };
}
