import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_FormPassword = _resolveComponent("FormPassword")!
  const _component_FormItemWrapper = _resolveComponent("FormItemWrapper")!
  const _component_PasswordMeter = _resolveComponent("PasswordMeter")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ContentCard = _resolveComponent("ContentCard")!
  const _component_grid_col = _resolveComponent("grid-col")!
  const _component_grid_row = _resolveComponent("grid-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageHeader, null, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('authentication.changePassword')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_grid_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_grid_col, { classList: "col-12 col-lg-6 mb-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_ContentCard, null, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
                }, [
                  _createVNode(_component_FormItemWrapper, {
                    label: _ctx.$t('changePassword.currentPassword'),
                    errors: _ctx.errors.oldPassword
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormPassword, {
                        modelValue: _ctx.form.oldPassword,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.oldPassword) = $event)),
                        errors: _ctx.errors.oldPassword
                      }, null, 8, ["modelValue", "errors"])
                    ]),
                    _: 1
                  }, 8, ["label", "errors"]),
                  _createVNode(_component_FormItemWrapper, {
                    label: _ctx.$t('changePassword.newPassword'),
                    errors: _ctx.errors.newPassword
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormPassword, {
                        modelValue: _ctx.form.newPassword,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.newPassword) = $event)),
                        errors: _ctx.errors.newPassword
                      }, null, 8, ["modelValue", "errors"]),
                      (_ctx.form.newPassword)
                        ? (_openBlock(), _createBlock(_component_PasswordMeter, {
                            key: 0,
                            password: _ctx.form.newPassword
                          }, null, 8, ["password"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["label", "errors"]),
                  _createVNode(_component_FormItemWrapper, {
                    label: _ctx.$t('changePassword.repeatNewPassword'),
                    errors: _ctx.errors.repeatNewPassword
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormPassword, {
                        modelValue: _ctx.form.repeatNewPassword,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.repeatNewPassword) = $event)),
                        errors: _ctx.errors.repeatNewPassword
                      }, null, 8, ["modelValue", "errors"])
                    ]),
                    _: 1
                  }, 8, ["label", "errors"]),
                  _createVNode(_component_Button, {
                    block: "",
                    type: "submit",
                    disabled: _ctx.isChangingPassword
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('changePassword.confirm')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ], 32)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}