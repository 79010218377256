import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-sm-flex align-items-center justify-content-between mb-4" }
const _hoisted_2 = { class: "h3 mb-0 text-gray-800" }
const _hoisted_3 = {
  key: 0,
  class: "actions d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header")
    ]),
    (_ctx.hasActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "actions")
        ]))
      : _createCommentVNode("", true)
  ]))
}