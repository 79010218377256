import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_FormItemWrapper = _resolveComponent("FormItemWrapper")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
  }, [
    _createVNode(_component_FormItemWrapper, {
      label: _ctx.$t('global.name'),
      errors: _ctx.errors.name
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormInput, {
          modelValue: _ctx.form.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
          errors: _ctx.errors.name,
          type: "text"
        }, null, 8, ["modelValue", "errors"])
      ]),
      _: 1
    }, 8, ["label", "errors"]),
    _createVNode(_component_FormItemWrapper, {
      label: _ctx.$t('global.email'),
      errors: _ctx.errors.email
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormInput, {
          modelValue: _ctx.form.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.email) = $event)),
          errors: _ctx.errors.email,
          readonly: !_ctx.isRoot && !_ctx.isAccount,
          type: "email"
        }, null, 8, ["modelValue", "errors", "readonly"])
      ]),
      _: 1
    }, 8, ["label", "errors"]),
    _createVNode(_component_FormItemWrapper, {
      label: _ctx.$t('global.phone'),
      errors: _ctx.errors.phone
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormInput, {
          modelValue: _ctx.form.phone,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.phone) = $event)),
          errors: _ctx.errors.phone,
          type: "tel"
        }, null, 8, ["modelValue", "errors"])
      ]),
      _: 1
    }, 8, ["label", "errors"]),
    (_ctx.isRoot && !_ctx.isAccount)
      ? (_openBlock(), _createBlock(_component_FormItemWrapper, {
          key: 0,
          label: _ctx.$t('user.emailConfirmed')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormInput, {
              modelValue: _ctx.translatedEmailConfirmed,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.translatedEmailConfirmed) = $event)),
              readonly: "",
              type: "text"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]))
      : _createCommentVNode("", true),
    (_ctx.isRoot && !_ctx.isAccount)
      ? (_openBlock(), _createBlock(_component_FormItemWrapper, {
          key: 1,
          label: _ctx.$t('user.lastLogin')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FormInput, {
              modelValue: _ctx.formattedLastLogin,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formattedLastLogin) = $event)),
              readonly: "",
              type: "text"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Button, {
      block: "",
      type: "submit",
      disabled: _ctx.isSaving
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('global.save')), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ], 32))
}