import { Ref, ref } from 'vue';
import { getOrderItems, OrderItemDto } from '../services';

export default function useOrderItems() {
  const result: Ref<OrderItemDto[]> = ref([]);
  const active = ref(false);

  const run = async (orderId: number) => {
    active.value = true;

    result.value = await getOrderItems(orderId);

    active.value = false;
  };

  return { result, active, run };
}
