
import { defineComponent, Ref, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

import { RouteKeys } from '@/router';

import ContentCard from '@components/layout/ContentCard.vue';
import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';
import PageHeader from '@components/layout/PageHeader.vue';

import Button from '@components/Button.vue';
import LoadingSpinner from '@components/LoadingSpinner.vue';

import ConfirmDeleteModal from '@modules/kernel/components/ConfirmDeleteModal.vue';
import ValidationCodeModal from '@modules/kernel/components/ValidationCodeModal.vue';
import { useModal } from '@modules/kernel/hooks';
import { logout } from '@modules/kernel/services';
import { useCurrentUserStore } from '@modules/kernel/stores';
import { createAsyncProcess } from '@modules/kernel/utils';

import UserAvatar from './components/UserAvatar.vue';
import UserDetails from './components/UserDetails.vue';
import { deleteCurrentUser, saveCurrentUser, UpdateCurrentUserDto } from './services';

export default defineComponent({
  components: {
    PageHeader,
    GridRow,
    GridCol,
    ContentCard,
    UserDetails,
    UserAvatar,
    Button,
    ConfirmDeleteModal,
    ValidationCodeModal,
    LoadingSpinner,
  },
  setup() {
    const toast = useToast();
    const { t } = useI18n();
    const router = useRouter();

    const { active: isSavingUser, run: runSaveCurrentUser } = createAsyncProcess(saveCurrentUser);
    const { active: isDeletingUser, run: runDeleteCurrentUser } = createAsyncProcess(deleteCurrentUser);

    const store = useCurrentUserStore();
    const { currentUser, isLoadingCurrentUser } = storeToRefs(store);

    /**
     * UPDATE
     */
    const {
      visible: isValidationCodeModalVisible,
      show: showValidationCodeModal,
      hide: hideValidationCodeModal,
    } = useModal();

    const form: Ref<UpdateCurrentUserDto | undefined> = ref(undefined);

    const submitForm = async (value: UpdateCurrentUserDto) => {
      try {
        await runSaveCurrentUser(value);
        toast.success(t('global.changesSaved'));
      } catch (error: any) {
        if (error.key === 'validationCodeRequired') {
          form.value = value;
          showValidationCodeModal();
          return;
        }
        toast.error(error.message);
      }
    };

    const submitValidationCode = async (value: string) => {
      try {
        await runSaveCurrentUser({ ...form.value!, validationCode: value });

        form.value = undefined;
        hideValidationCodeModal();

        toast.success(t('global.changesSaved'));
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    /**
     * DELETE
     */
    const {
      visible: isConfirmDeleteModalVisible,
      show: showConfirmDeleteModal,
      hide: hideConfirmDeleteModal,
    } = useModal();

    const submitDelete = async (password: string) => {
      try {
        await runDeleteCurrentUser(password);
        logout();

        toast.success(t('global.successfullyDeleted'));
        router.push({ name: RouteKeys.Login });
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    return {
      currentUser,
      isConfirmDeleteModalVisible,
      isDeletingUser,
      isLoadingCurrentUser,
      isSavingUser,
      isValidationCodeModalVisible,

      hideConfirmDeleteModal,
      hideValidationCodeModal,
      showConfirmDeleteModal,
      submitDelete,
      submitForm,
      submitValidationCode,
    };
  },
});
