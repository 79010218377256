import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-134e9c27"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "wrapper"
}
const _hoisted_2 = {
  id: "content-wrapper",
  class: "d-flex flex-column"
}
const _hoisted_3 = { id: "content" }
const _hoisted_4 = { class: "container-fluid" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_FormPassword = _resolveComponent("FormPassword")!
  const _component_FormItemWrapper = _resolveComponent("FormItemWrapper")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.currentUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Sidebar),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Header),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_router_view)
              ])
            ]),
            _createVNode(_component_Footer)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_LoadingSpinner, {
            classList: "text-white",
            fullscreen: ""
          })
        ])),
    (_ctx.isSessionExpired)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 2,
          size: "md",
          fixed: ""
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('authentication.sessionExpired.modalTitle')), 1)
          ]),
          body: _withCtx(() => [
            _createElementVNode("p", {
              innerHTML: _ctx.$t('authentication.sessionExpired.modalBody')
            }, null, 8, _hoisted_6),
            _createElementVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitLogin && _ctx.submitLogin(...args)), ["prevent"]))
            }, [
              _createVNode(_component_FormItemWrapper, {
                errors: _ctx.errors.password
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormPassword, {
                    modelValue: _ctx.form.password,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.password) = $event)),
                    placeholder: _ctx.$t('global.password'),
                    errors: _ctx.errors.password,
                    showToggle: ""
                  }, null, 8, ["modelValue", "placeholder", "errors"])
                ]),
                _: 1
              }, 8, ["errors"]),
              _withDirectives(_createVNode(_component_Button, null, null, 512), [
                [_vShow, false]
              ])
            ], 32)
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              color: "primary",
              onClick: _ctx.submitLogin,
              disabled: _ctx.isLoggingIn
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('authentication.confirmLogin')), 1)
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}