import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h2 text-gray-800 mb-0" }
const _hoisted_2 = { class: "h6 text-gray-500 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorCode = _resolveComponent("ErrorCode")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorCode)
      ? (_openBlock(), _createBlock(_component_ErrorCode, {
          key: 0,
          code: _ctx.errorCode,
          class: "mx-auto"
        }, null, 8, ["code"]))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t(`errors.${_ctx.errorKey}`)), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t(`errors.${_ctx.errorKey}.subtitle`)), 1),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          color: "primary",
          outline: "",
          onClick: _ctx.goHome,
          class: "m-1"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('errors.goHome')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Button, {
      color: "primary",
      onClick: _ctx.goBack,
      class: "m-1"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('errors.goPreviousPage')), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}