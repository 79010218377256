
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import PasswordMeter from 'vue-simple-password-meter';
import { useToast } from 'vue-toastification';

import { RouteKeys } from '@/router';

import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';

import Button from '@components/Button.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import FormPassword from '@components/form/FormPassword.vue';

import { useForm } from '@modules/kernel/hooks';
import { createAsyncProcess } from '@modules/kernel/utils';

import { resetPassword } from './services';

export default defineComponent({
  components: { GridRow, GridCol, FormItemWrapper, FormPassword, Button, PasswordMeter },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { t } = useI18n();

    const { active: isResettingPassword, run: runResetPassword } = createAsyncProcess(resetPassword);

    const token = route.query?.token as string;

    const { form, errors, validate } = useForm({
      newPassword: {
        type: String,
        required: true,
      },
      repeatNewPassword: {
        type: String,
        required: true,
        sameAs: { key: 'newPassword', label: t('changePassword.newPassword') },
      },
    });

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      try {
        const response = await runResetPassword(token, form.newPassword);
        toast.success(t('changePassword.successfullyChanged'));
        router.push({ name: response ? RouteKeys.Dashboard : RouteKeys.Login });
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    return { errors, form, isResettingPassword, submitForm };
  },
});
