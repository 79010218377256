import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { CustomerDto, UpdateCustomerDto } from './types';

export async function getCustomers(
  search?: string,
  paginationFilters?: PaginationFilters
): Promise<PaginatedResponse<CustomerDto>> {
  const response = await api.get('/customers', { params: { ...paginationFilters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new CustomerDto(item)),
  };
}

export async function getCustomer(id: number): Promise<CustomerDto> {
  const response = await api.get(`/customers/${id}`);
  return new CustomerDto(response.data);
}

export async function saveCustomer(id: number, form: UpdateCustomerDto): Promise<void> {
  const response = await api.put(`/customers/${id}`, new UpdateCustomerDto(form));
  return response?.data;
}
