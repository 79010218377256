export class TextDto {
  id: number;
  label: string;
  key: string;
  value: string;
  amountOfRules: number;

  constructor(dto: TextDto) {
    Object.assign(this, dto);
  }
}
