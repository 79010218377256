import { Ref, ref } from 'vue';
import { ColorDto, getColors } from '../services';

const result: Ref<ColorDto[]> = ref([]);
const active = ref(false);

export default function useColors() {
  const run = async () => {
    active.value = true;

    result.value = await getColors();

    active.value = false;
  };

  return { result, active, run };
}
