
import { defineComponent, PropType } from 'vue';

type Tab = {
  key: string;
  label?: string;
  translationKey?: string;
};

export default defineComponent({
  props: {
    tab: {
      type: Object as PropType<Tab>,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const handleClick = () => {
      emit('click', props.tab.key);
    };

    return { handleClick };
  },
});
