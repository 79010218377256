import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    "body-translation-key": _ctx.bodyTranslationkey,
    "button-translation-key": "global.delete",
    color: "danger",
    "is-loading": _ctx.isDeleting,
    "ask-password": _ctx.askPassword,
    onClose: _ctx.closeModal,
    onConfirm: _ctx.confirmDelete
  }, null, 8, ["body-translation-key", "is-loading", "ask-password", "onClose", "onConfirm"]))
}