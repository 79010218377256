import { OrderStatus, PaymentStatus } from '../../types';

export class OrderDto {
  id: number;
  date: Date;
  name: string;
  companyName?: string;
  companyVat?: string;
  phone?: string;
  email: string;
  street: string;
  postalCode: string;
  city: string;
  deliveryMethod: 'pickup' | 'delivery';
  deliveryStreet?: string;
  deliveryPostalCode?: string;
  deliveryCity?: string;
  remarks?: string;
  status: OrderStatus;
  paymentId?: string;
  paymentStatus?: PaymentStatus;
  paymentAmount?: number;

  constructor(dto: OrderDto) {
    Object.assign(this, dto);
  }
}
