export class UpdateColorDto {
  colorCategoryId: number;
  name: string;
  colorCode?: string;
  imageId?: number;

  constructor(dto: UpdateColorDto) {
    this.colorCategoryId = dto.colorCategoryId;
    this.name = dto.name;
    this.colorCode = dto.colorCode;
    this.imageId = dto.imageId;
  }
}
