import { Ref, ref } from 'vue';
import { getLogs, LogDto } from '../services';
import { Log, mapDtoToLog } from '../types';

const result: Ref<Log[]> = ref([]);
const active = ref(false);

export default function useLogs() {
  const run = async (search?: string) => {
    active.value = true;

    const logsDto: LogDto[] = await getLogs(search);
    result.value = logsDto.map(mapDtoToLog);

    active.value = false;
  };

  return { result, active, run };
}
