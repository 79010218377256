
import { computed, ComputedRef, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Button from '@components/Button.vue';
import FormDropdown, { Option } from '@components/form/FormDropdown.vue';
import FormInput from '@components/form/FormInput.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import Modal from '@components/Modal.vue';

import { useForm } from '@modules/kernel/hooks';
import { createAsyncProcess } from '@modules/kernel/utils';

import useRoles from './hooks/use-roles';
import { createUser } from './services';

export default defineComponent({
  components: { Modal, Button, FormItemWrapper, FormInput, FormDropdown },
  emits: ['close'],
  setup(_, { emit }) {
    const toast = useToast();
    const { t } = useI18n();

    const { result: roles, active: isFetchingRoles, run: runGetRoles } = useRoles();
    const { active: isAddingUser, run: runCraeteUser } = createAsyncProcess(createUser);

    const {
      form,
      errors,
      validate,
      isTouched: isFormTouched,
    } = useForm({
      name: {
        type: String,
        required: true,
      },
      email: {
        type: String,
        required: true,
        email: true,
      },
      phone: {
        type: String,
        required: false,
      },
      role: {
        type: Number,
        required: true,
      },
    });

    const roleOptions: ComputedRef<Option[]> = computed(() =>
      roles.value
        .filter((role) => role.allowRegistrations)
        .map((role) => ({ value: role.id, translationKey: `role.${role.key}` }))
    );

    const getRoles = async () => {
      await runGetRoles();

      if (roles.value.length === 1) {
        form.role = roles.value[0]?.id;
      }
    };
    getRoles();

    const closeModal = () => {
      emit('close');
    };

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      try {
        await runCraeteUser(form);

        toast.success(t('global.successfullyCreated'));
        closeModal();
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    return {
      errors,
      form,
      isAddingUser,
      isFetchingRoles,
      isFormTouched,
      roleOptions,

      closeModal,
      submitForm,
    };
  },
});
