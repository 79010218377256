
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<any>,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      required: true,
    },
    errors: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const filteredErrors = computed(() => props.errors.filter((error) => error));

    const handleFileUpload = (event: any) => {
      const files: FileList = event.target.files;

      const result: File[] = [];
      for (let i = 0; i < files.length; i++) {
        result.push(files.item(i) as File);
      }

      if (result.length) {
        const payload = props.multiple ? result : result[0];
        emit('update:modelValue', payload);
      }
    };

    return { filteredErrors, handleFileUpload };
  },
});
