export class ArticleDto {
  id: number;
  number: string;
  name: string;
  totalPrice: number;

  constructor(dto: ArticleDto) {
    Object.assign(this, dto);
  }
}
