import { parseDate } from '@utils/date.util';

export class UserDto {
  id: number;
  name: string;
  email: string;
  emailConfirmed: boolean;
  phone?: string;
  active: boolean;
  imageUrl?: string;
  lastLogin?: Date;

  constructor({ lastLogin, ...rest }: UserDto) {
    this.lastLogin = parseDate(lastLogin);
    Object.assign(this, rest);
  }
}
