import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card shadow h-100" }
const _hoisted_2 = {
  key: 0,
  class: "card-header d-flex justify-content-between"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h6", {
            class: "m-0 font-weight-bold text-primary",
            innerHTML: _ctx.title
          }, null, 8, _hoisted_3),
          _renderSlot(_ctx.$slots, "actions")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}