
import { computed, defineComponent, watch } from 'vue';
import { Tooltip } from 'floating-vue';
import { storeToRefs } from 'pinia';
import { useToast } from 'vue-toastification';

import router, { RouteKeys } from '@/router';

import Badge from '@components/Badge.vue';
import Button from '@components/Button.vue';
import LoadingSpinner from '@components/LoadingSpinner.vue';
import Modal from '@components/Modal.vue';
import Notification from '@components/Notification.vue';
import Pane from '@components/Pane.vue';

import { useModal, useNotifications } from '@modules/kernel/hooks';
import { logout } from '@modules/kernel/services';
import { useCurrentUserStore } from '@modules/kernel/stores';

import { useSidebarStore } from '@modules/layout/stores';

export default defineComponent({
  components: {
    Modal,
    Pane,
    Button,
    Notification,
    Badge,
    LoadingSpinner,
    Tooltip,
  },
  setup() {
    const toast = useToast();

    const currentUserStore = useCurrentUserStore();
    const { currentUser } = storeToRefs(currentUserStore);

    const {
      active: isLoadingNotifications,
      result: notificationGroup,
      run: runGetNotifications,
      markAsSeen: runMarkAsSeen,
      deleteNotification: runDeleteNotification,
    } = useNotifications();

    const notifications = computed(() => notificationGroup.value.items);
    const newNotificationCount = computed(() => notificationGroup.value.newCount);

    const sidebarStore = useSidebarStore();
    const { toggleSidebar } = sidebarStore;

    /**
     * AVATAR
     */
    const imageUrl = computed(() => {
      if (currentUser.value?.imageUrl) {
        return currentUser.value.imageUrl;
      }
      return require('@/assets/img/avatar.jpg');
    });

    /**
     * DROPDOWN / MODAL / PANE
     */
    const { visible: isUserDropdownVisible, hide: hideUserDropdown, toggle: toggleUserDropdown } = useModal();
    const { visible: isSignOutModalVisible, hide: hideSignOutModal, toggle: toggleSignOutModal } = useModal();

    const submitSignOut = () => {
      logout();
      router.push({ name: RouteKeys.Login });
    };

    /**
     * NOTIFICATIONS PANE
     */
    const {
      visible: isNotificationsPaneVisible,
      hide: hideNotificationsPane,
      toggle: toggleNotificationsPane,
    } = useModal();

    watch(isNotificationsPaneVisible, async (current, previous) => {
      if (previous === false && current === true) {
        // pane is opened
        await runGetNotifications();
        runMarkAsSeen();
      }
    });

    runGetNotifications();

    const submitDelete = async (id: number) => {
      try {
        await runDeleteNotification(id);
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    return {
      currentUser,
      imageUrl,
      isLoadingNotifications,
      isNotificationsPaneVisible,
      isSignOutModalVisible,
      isUserDropdownVisible,
      newNotificationCount,
      notifications,
      hideNotificationsPane,
      hideSignOutModal,
      hideUserDropdown,
      submitDelete,
      submitSignOut,
      toggleNotificationsPane,
      toggleSidebar,
      toggleSignOutModal,
      toggleUserDropdown,
    };
  },
});
