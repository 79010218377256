
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    content: {
      type: [Object, Array],
      required: false,
    },
  },
  setup(props) {
    const formattedJson = computed(() => {
      return JSON.stringify(props.content, null, 2);
    });

    return { formattedJson };
  },
});
