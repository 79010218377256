import api from '@/custom/api';
import { ChangePasswordDto, UpdateCurrentUserDto } from './types';

export async function saveCurrentUser(form: UpdateCurrentUserDto): Promise<void> {
  const response = await api.put('/users/me', new UpdateCurrentUserDto(form));
  return response?.data;
}

export async function changePassword(form: ChangePasswordDto): Promise<void> {
  const response = await api.put('/users/me/password', new ChangePasswordDto(form));
  return response?.data;
}

export async function changeAvatar(imageId: number): Promise<void> {
  const response = await api.put('/users/me/avatar', {
    image_id: imageId,
  });

  return response?.data;
}

export async function deleteCurrentUser(password: string): Promise<void> {
  const response = await api.delete('/users/me', { params: { password } });
  return response?.data;
}
