import { ArticleListDto } from './article-list-dto';

export class CreateClosetStructureDto {
  number: string;
  name: string;
  extraPrice: number | undefined;
  imageId: number;
  articleList: ArticleListDto[];
  doorArticleList: ArticleListDto[];
  type: 'single' | 'double';
  closetCategoryIds: number[];
  customizationOptions: string[];

  constructor(dto: CreateClosetStructureDto) {
    this.number = dto.number;
    this.name = dto.name;
    this.extraPrice = dto.extraPrice;
    this.imageId = dto.imageId;
    this.articleList = dto.articleList.map((item) => new ArticleListDto(item));
    this.doorArticleList = dto.doorArticleList.map((item) => new ArticleListDto(item));
    this.type = dto.type;
    this.closetCategoryIds = dto.closetCategoryIds;
    this.customizationOptions = dto.customizationOptions;
  }
}
