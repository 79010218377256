import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-523b884a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["accept", "multiple"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "file",
    accept: _ctx.accept,
    multiple: _ctx.multiple,
    class: _normalizeClass(["form-control", { 'is-invalid': _ctx.filteredErrors.length }]),
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args)))
  }, null, 42, _hoisted_1))
}