import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createBlock(_component_Multiselect, {
    value: _ctx.modelValue,
    mode: "tags",
    class: _normalizeClass([_ctx.filteredErrors.length ? 'is-invalid' : '', _ctx.colorClass]),
    "close-on-select": false,
    disabled: _ctx.readonly,
    searchable: _ctx.searchable,
    options: _ctx.mappedOptions,
    "no-results-text": _ctx.$t('dropdown.noOptions'),
    onChange: _ctx.handleChange
  }, null, 8, ["value", "class", "disabled", "searchable", "options", "no-results-text", "onChange"]))
}