import { ColorDto } from '../services';

export interface Color {
  id: number;
  name: string;
  colorCode?: string;
  colorCategoryId: number;
  colorCategoryName: string;
}

export function mapDtoToColor(dto: ColorDto): Color {
  return {
    id: dto.id,
    name: dto.name,
    colorCode: dto.colorCode,
    colorCategoryId: dto.colorCategory.id,
    colorCategoryName: dto.colorCategory.name,
  };
}
