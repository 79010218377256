export class UpdateDeliverySettingsDto {
  deliveryPricePerKilometer: number;
  deliveryFreeKilometers: number;
  deliveryMaxKilometers: number;
  deliveryCostStreet: string;
  deliveryCostCity: string;
  deliveryCostPostalCode: string;

  constructor(dto: UpdateDeliverySettingsDto) {
    this.deliveryPricePerKilometer = dto.deliveryPricePerKilometer;
    this.deliveryFreeKilometers = dto.deliveryFreeKilometers;
    this.deliveryMaxKilometers = dto.deliveryMaxKilometers;
    this.deliveryCostStreet = dto.deliveryCostStreet;
    this.deliveryCostCity = dto.deliveryCostCity;
    this.deliveryCostPostalCode = dto.deliveryCostPostalCode;
  }
}
