
import { computed, ComputedRef, defineComponent } from 'vue';

import PageHeader from '@components/layout/PageHeader.vue';

import Tabs, { Tab } from '@components/tabs/Tabs.vue';

import DynamicWebsiteImagesWrapper from './components/DynamicWebsiteImagesWrapper.vue';
import usePages from './hooks/use-pages';
import { PageDto } from './services';

export default defineComponent({
  components: { PageHeader, Tabs },
  setup() {
    const { result: pages, active: isLoadingPages, run: runGetPages } = usePages();
    runGetPages();

    const tabs: ComputedRef<Tab[]> = computed(() =>
      pages.value.map((page: PageDto) => ({
        key: page.key,
        label: page.label,
        component: DynamicWebsiteImagesWrapper,
      }))
    );

    return { tabs, isLoadingPages };
  },
});
