import { onMounted, Ref, ref } from 'vue';
import {
  deleteNotification as deleteNotificationApi,
  getNotifications,
  markAsSeen as markAsSeenApi,
  NotificationGroupDto,
} from '../services';

const result: Ref<NotificationGroupDto> = ref({ newCount: 0, items: [] });
const active = ref(false);

export function useNotifications() {
  const run = async () => {
    active.value = true;

    result.value = await getNotifications();

    active.value = false;
  };

  const markAsSeen = async () => {
    const newItems = result.value.items.filter((item) => !item.seen);
    if (newItems.length > 0) {
      await markAsSeenApi(newItems.map((item) => item.id));
    }

    result.value.newCount = 0;
  };

  const deleteNotification = async (id: number) => {
    await deleteNotificationApi(id);
    result.value.items = result.value.items.filter((item) => item.id !== id);
  };

  onMounted(async () => {
    run();
  });

  return { active, result, run, markAsSeen, deleteNotification };
}
