import { Ref, ref } from 'vue';
import { ArticleDto, getArticles } from '../services';

const result: Ref<ArticleDto[]> = ref([]);
const active = ref(false);

export default function useArticles() {
  const run = async () => {
    active.value = true;

    result.value = await getArticles();

    active.value = false;
  };

  const runIfNoData = async () => {
    if (result.value.length === 0) {
      await run();
    }
  };

  return { result, active, run, runIfNoData };
}
