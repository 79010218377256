import { computed, reactive } from 'vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { email, maxLength, maxValue, minLength, minValue, required, requiredIf } from '@vuelidate/validators';
import {
  getEmailError,
  getMaxLengthError,
  getMaxValueError,
  getMinLengthError,
  getMinValueError,
  getRequiredError,
  getSameAsError,
} from '@utils/vuelidate.util';

type Setup<T> = {
  [key: string]: FormItem<T>;
};

type Options = {
  validateNested?: boolean;
  translator?: (key: string, params?: string[]) => string;
};

type CommonFormItem<T> = {
  required: boolean | ((form: Form<any>) => boolean);
  sameAs?: T | { key: T; label: string };
  email?: boolean;
  minLength?: number;
  maxLength?: number;
  minValue?: number | string;
  maxValue?: number | string;
  default?: any;
};

type FormItem<T> =
  | (CommonFormItem<T> & { type: typeof String; default?: string })
  | (CommonFormItem<T> & { type: typeof Number; default?: number })
  | (CommonFormItem<T> & { type: typeof Boolean; default: boolean })
  | (CommonFormItem<T> & { type: typeof Date; default?: Date })
  | (CommonFormItem<T> & { type: typeof Array; default?: Array<any> })
  | (CommonFormItem<T> & { type: typeof Blob });

type Form<T> = Record<keyof T, any>;
type FormRules<T> = Record<keyof T, any>;
type FormErrors<T> = Record<keyof T, string[]>;

export function useForm<T extends Setup<keyof T>>(fields: T, options?: Options) {
  const fieldsAsArray: Array<[string, FormItem<keyof T>]> = Object.entries(fields);

  // Helpers
  const getVuelidateValidation = (props: FormItem<keyof T>): ValidationArgs => {
    const validation: ValidationArgs = {};

    if (typeof props.required === 'function' && props.required !== undefined) {
      const func = typeof props.required === 'function' ? props.required : () => false;
      validation['required'] = requiredIf(() => func(form));
    }
    if (typeof props.required === 'boolean' && props.required) {
      validation['required'] = required;
    }
    if (props.email) {
      validation['email'] = email;
    }
    if (props.minLength) {
      validation['minLength'] = minLength(props.minLength);
    }
    if (props.maxLength) {
      validation['maxLength'] = maxLength(props.maxLength);
    }
    if (props.minValue !== undefined && props.minValue !== null) {
      validation['minValue'] = minValue(props.minValue);
    }
    if (props.maxValue !== undefined && props.maxValue !== null) {
      validation['maxValue'] = maxValue(props.maxValue);
    }
    if (props.sameAs) {
      const key = typeof props.sameAs === 'object' ? props.sameAs.key : props.sameAs;
      validation['sameAs'] = (value: any) => value === form[key];
    }

    return validation;
  };

  const getVuelidateErrors = (key: string, props: FormItem<keyof T>) => {
    return [
      getRequiredError(v$.value[key], options?.translator),

      getEmailError(v$.value[key], options?.translator),

      getMinLengthError(v$.value[key], options?.translator),
      getMaxValueError(v$.value[key], options?.translator),

      getMinValueError(v$.value[key], options?.translator),
      getMaxLengthError(v$.value[key], options?.translator),

      getSameAsError(
        v$.value[key],
        typeof props.sameAs === 'object' ? props.sameAs.label : (props.sameAs as string),
        options?.translator
      ),
    ];
  };

  // Initialize form
  const form = reactive<Form<T>>(
    fieldsAsArray.reduce((previous, [key, props]) => {
      return {
        ...previous,
        [key]: 'default' in props ? props.default : undefined,
      };
    }, {} as Form<T>)
  );

  // Inititalize rules
  const rules = fieldsAsArray.reduce<FormRules<T>>((previous, [key, props]) => {
    return {
      ...previous,
      [key]: getVuelidateValidation(props),
    };
  }, {} as FormRules<T>);

  // Build vuelidate validation
  const v$ = useVuelidate(rules, form, { $scope: !!options?.validateNested });

  // Build error response
  const errors = computed<FormErrors<T>>(() =>
    fieldsAsArray.reduce((previous, [key, props]) => {
      return {
        ...previous,
        [key]: getVuelidateErrors(key, props).filter((error) => error),
      };
    }, {} as FormErrors<T>)
  );

  // Check if form is touched
  const isTouched = computed(
    () => !fieldsAsArray.every(([fieldName, fieldOptions]) => (form[fieldName] || undefined) === fieldOptions.default)
  );

  // Trigger validation
  const validate = async (props?: string[]) => {
    if (props?.length) {
      const results = await Promise.all(props.map((prop) => v$.value[prop].$validate()));
      return results.every((result) => result === true);
    }
    return v$.value.$validate();
  };

  // Reset
  const resetForm = () => {
    for (const [fieldName, fieldOptions] of fieldsAsArray) {
      form[fieldName as keyof T] = fieldOptions.default;
    }

    resetValidation();
  };
  const resetValidation = () => v$.value.$reset();

  return {
    form,
    errors,
    isTouched,
    validate,
    resetValidation,
    resetForm,
  };
}
