
import { computed, defineComponent, onMounted, PropType, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useModal } from '@modules/kernel/hooks';

type DropdownItem = {
  key: string;
  link: string;
  visible?: boolean;
};

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    children: {
      type: Array as PropType<Array<DropdownItem>>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();

    const formatTitle = (title: string) => title.charAt(0).toUpperCase() + title.slice(1);

    const formattedTitle = computed(() => formatTitle(props.title));
    const iconClass = computed(() => `fa-${props.icon}`);

    const filteredChildren = computed(() => props.children.filter((child) => child.visible ?? true));

    /**
     * COLLAPSE
     */
    const {
      visible: isNavDropdownVisible,
      toggle: toggleNavDropdown,
      show: showNavDropdown,
      hide: hideNavDropdown,
    } = useModal();

    watch(
      () => route.name,
      (current, previous) => {
        if (current === previous) {
          return;
        }

        const currentPageIsChild = props.children.some((child) => child.link === current);
        const isScreenWidthSmall = window.matchMedia('only screen and (max-width: 770px)').matches;
        if (currentPageIsChild && !isScreenWidthSmall) {
          showNavDropdown();
          return;
        }

        hideNavDropdown();
      }
    );
    onMounted(() => {
      const currentPageIsChild = props.children.some((child) => child.link === route.name);
      const isScreenWidthSmall = window.matchMedia('only screen and (max-width: 770px)').matches;
      if (currentPageIsChild && !isScreenWidthSmall) {
        showNavDropdown();
        return;
      }

      hideNavDropdown();
    });

    return { filteredChildren, formattedTitle, iconClass, isNavDropdownVisible, formatTitle, toggleNavDropdown };
  },
});
