import { Ref, ref } from 'vue';
import { getEmptyPageMetadata, PageMetadata, PaginationFilters } from '@modules/kernel/types';
import { CustomerDto, getCustomers } from '../services';

const result: Ref<CustomerDto[]> = ref([]);
const metadata: Ref<PageMetadata> = ref(getEmptyPageMetadata());
const active = ref(false);

export default function useCustomers() {
  const run = async (search?: string, paginationFilters?: PaginationFilters) => {
    active.value = true;

    const response = await getCustomers(search, paginationFilters);
    result.value = response.items;
    metadata.value = response.page;

    active.value = false;
  };

  return { result, metadata, active, run };
}
