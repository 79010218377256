import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "flex-column nav-pills" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body tab-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabButton = _resolveComponent("TabButton")!
  const _component_grid_col = _resolveComponent("grid-col")!
  const _component_TabContent = _resolveComponent("TabContent")!
  const _component_grid_row = _resolveComponent("grid-row")!

  return (_openBlock(), _createBlock(_component_grid_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_grid_col, { classList: "col-md-4 col-xl-2" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleTabs, (tab) => {
                  return (_openBlock(), _createBlock(_component_TabButton, {
                    key: tab.key,
                    tab: tab,
                    active: tab.key === _ctx.activeTab,
                    onClick: _ctx.setActiveTab
                  }, null, 8, ["tab", "active", "onClick"]))
                }), 128))
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_grid_col, { classList: "col-md-8 col-xl-10" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleTabs, (tab) => {
                return (_openBlock(), _createBlock(_component_TabContent, {
                  key: tab.key,
                  tab: tab,
                  active: tab.key === _ctx.activeTab
                }, null, 8, ["tab", "active"]))
              }), 128))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}