
import { defineComponent, PropType, ref } from 'vue';

import { Tab } from '@components/tabs/Tabs.vue';

import useTexts from '../hooks/use-texts';

import DynamicWebsiteTexts from './DynamicWebsiteTexts.vue';

export default defineComponent({
  components: { DynamicWebsiteTexts },
  props: {
    data: {
      type: Object as PropType<Tab>,
      required: true,
    },
  },
  setup(props) {
    const pageKey = ref(props.data.key);

    const { result: texts, active: isLoadingTexts, run: runGetTexts } = useTexts();
    runGetTexts(pageKey.value);

    return { pageKey, texts, isLoadingTexts };
  },
});
