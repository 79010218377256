import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  classList: "text-center my-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_DynamicWebsiteTexts = _resolveComponent("DynamicWebsiteTexts")!

  return (_ctx.isLoadingTexts)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_LoadingSpinner)
      ]))
    : (_openBlock(), _createBlock(_component_DynamicWebsiteTexts, {
        key: 1,
        page: _ctx.pageKey,
        texts: _ctx.texts
      }, null, 8, ["page", "texts"]))
}