import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { UpdateUserDto } from './types/update-user-dto';
import { CreateUserDto, UserDto } from './types';

export async function getUsers(
  search?: string,
  paginationFilters?: PaginationFilters
): Promise<PaginatedResponse<UserDto>> {
  const response = await api.get('/users', { params: { ...paginationFilters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new UserDto(item)),
  };
}

export async function getUser(id: number): Promise<UserDto> {
  const response = await api.get(`/users/${id}`);
  return new UserDto(response.data);
}

export async function saveUser(id: number, form: UpdateUserDto): Promise<void> {
  const response = await api.put(`/users/${id}`, new UpdateUserDto(form));
  return response?.data;
}

export async function createUser(form: CreateUserDto): Promise<void> {
  const response = await api.post('/users', new CreateUserDto(form));
  return response?.data;
}

export async function deleteUser(id: number, password: string): Promise<void> {
  const response = await api.delete(`/users/${id}`, { params: { password } });
  return response?.data;
}
