
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Button from '@components/Button.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import FormUpload from '@components/form/FormUpload.vue';

import { useForm } from '@modules/kernel/hooks';
import { CurrentUserDto, uploadImage } from '@modules/kernel/services';
import { useCurrentUserStore } from '@modules/kernel/stores';
import { createAsyncProcess } from '@modules/kernel/utils';

import { changeAvatar } from '../services';

export default defineComponent({
  components: { FormItemWrapper, FormUpload, Button },
  props: {
    user: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const toast = useToast();
    const { t } = useI18n();

    const { active: isUploadingImage, run: runUploadImage } = createAsyncProcess(uploadImage);
    const { active: isChangingAvatar, run: runChangeAvatar } = createAsyncProcess(changeAvatar);
    const { setCurrentUser } = useCurrentUserStore();

    const imageUrl = computed(() => {
      if (props.user?.imageUrl) {
        return props.user.imageUrl;
      }
      return require('@/assets/img/avatar.jpg');
    });

    const { form, errors, validate, resetForm } = useForm({
      image: {
        type: Blob,
        required: true,
      },
    });

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      try {
        const { id, url } = await runUploadImage(form.image!, { width: 300, height: 300 });
        await runChangeAvatar(id);

        toast.success(t('global.changesSaved'));
        setCurrentUser({ ...props.user, imageUrl: url } as CurrentUserDto);
        resetForm();
      } catch (error: any) {
        toast.error(error.message);
        return;
      }
    };

    return { errors, form, imageUrl, isChangingAvatar, isUploadingImage, submitForm };
  },
});
