import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-402489d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "value", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-control", { 'is-invalid': _ctx.filteredErrors.length }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("label", {
        key: option.value
      }, [
        _createElementVNode("input", {
          type: "checkbox",
          disabled: _ctx.readonly || option.readonly,
          value: option.value,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
          checked: _ctx.isOptionSelected(option.value)
        }, null, 40, _hoisted_1),
        _createTextVNode(" " + _toDisplayString(option.translationKey ? _ctx.$t(option.translationKey) : option.label ?? ''), 1)
      ]))
    }), 128))
  ], 2))
}