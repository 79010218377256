export class ClosetGripDto {
  id: number;
  number: string;
  name: string;
  price: number;
  imageId: number;
  imageUrl?: string;

  constructor(dto: ClosetGripDto) {
    Object.assign(this, dto);
  }
}
