export class UpdateColorSampleOrderDto {
  name: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  colorIds: number[];
  internalRemarks?: string;

  constructor(dto: UpdateColorSampleOrderDto) {
    this.name = dto.name;
    this.email = dto.email;
    this.address = dto.address;
    this.postalCode = dto.postalCode;
    this.city = dto.city;
    this.colorIds = dto.colorIds;
    this.internalRemarks = dto.internalRemarks;
  }
}
