import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center my-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_FormDropdown = _resolveComponent("FormDropdown")!
  const _component_FormItemWrapper = _resolveComponent("FormItemWrapper")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_grid_col = _resolveComponent("grid-col")!
  const _component_Button = _resolveComponent("Button")!
  const _component_grid_row = _resolveComponent("grid-row")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
  }, [
    (_ctx.isLoadingSettings)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LoadingSpinner)
        ]))
      : (_openBlock(), _createBlock(_component_grid_row, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_grid_col, { "class-list": "col-xl-6 col-12" }, {
              default: _withCtx(() => [
                _createVNode(_component_FormItemWrapper, {
                  label: _ctx.$t('settings.root.defaultLanguage'),
                  errors: _ctx.errors.defaultLanguage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormDropdown, {
                      modelValue: _ctx.form.defaultLanguage,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.defaultLanguage) = $event)),
                      options: _ctx.languageOptions,
                      errors: _ctx.errors.defaultLanguage
                    }, null, 8, ["modelValue", "options", "errors"])
                  ]),
                  _: 1
                }, 8, ["label", "errors"]),
                _createVNode(_component_FormItemWrapper, {
                  label: _ctx.$t('settings.root.logRetentionPeriod.info'),
                  errors: _ctx.errors.infoLogRetentionPeriod
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormInput, {
                      modelValue: _ctx.form.infoLogRetentionPeriod,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.infoLogRetentionPeriod) = $event)),
                      errors: _ctx.errors.infoLogRetentionPeriod,
                      type: "number"
                    }, null, 8, ["modelValue", "errors"])
                  ]),
                  _: 1
                }, 8, ["label", "errors"]),
                _createVNode(_component_FormItemWrapper, {
                  label: _ctx.$t('settings.root.logRetentionPeriod.warning'),
                  errors: _ctx.errors.warningLogRetentionPeriod
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormInput, {
                      modelValue: _ctx.form.warningLogRetentionPeriod,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.warningLogRetentionPeriod) = $event)),
                      errors: _ctx.errors.warningLogRetentionPeriod,
                      type: "number"
                    }, null, 8, ["modelValue", "errors"])
                  ]),
                  _: 1
                }, 8, ["label", "errors"]),
                _createVNode(_component_FormItemWrapper, {
                  label: _ctx.$t('settings.root.logRetentionPeriod.error'),
                  errors: _ctx.errors.errorLogRetentionPeriod
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormInput, {
                      modelValue: _ctx.form.errorLogRetentionPeriod,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.errorLogRetentionPeriod) = $event)),
                      errors: _ctx.errors.errorLogRetentionPeriod,
                      type: "number"
                    }, null, 8, ["modelValue", "errors"])
                  ]),
                  _: 1
                }, 8, ["label", "errors"])
              ]),
              _: 1
            }),
            _createVNode(_component_grid_col, { "class-list": "col-xl-6 col-12" }),
            _createVNode(_component_grid_col, { "class-list": "col-12 mt-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  type: "submit",
                  disabled: _ctx.isSavingSettings
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.save')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
  ], 32))
}