
import { computed, defineComponent, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import PasswordMeter from 'vue-simple-password-meter';
import { useToast } from 'vue-toastification';

import router, { RouteKeys } from '@/router';

import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';

import Button from '@components/Button.vue';
import FormCheckbox from '@components/form/FormCheckbox.vue';
import FormInput from '@components/form/FormInput.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import FormPassword from '@components/form/FormPassword.vue';
import LoadingSpinner from '@components/LoadingSpinner.vue';

import { useCountdown, useFeatureFlag, useForm, useLocalstorage } from '@modules/kernel/hooks';
import { login } from '@modules/kernel/services/auth-service';
import { useCurrentUserStore } from '@modules/kernel/stores';
import { createAsyncProcess } from '@modules/kernel/utils';

export default defineComponent({
  components: {
    GridRow,
    GridCol,
    FormItemWrapper,
    FormInput,
    FormPassword,
    Button,
    LoadingSpinner,
    FormCheckbox,
    PasswordMeter,
  },
  setup() {
    const toast = useToast();
    const route = useRoute();
    const { t } = useI18n();
    const { getLocalstorage, setLocalstorage } = useLocalstorage();

    const { active: isLoggingIn, run: runLogin } = createAsyncProcess(login);

    const currentUserStore = useCurrentUserStore();
    const { isLoadingCurrentUser } = storeToRefs(currentUserStore);

    const { isFlagEnabled } = useFeatureFlag();
    const isKeepMeLoggedInVisible = computed(() => isFlagEnabled('remember-login', false));
    const isResetPasswordVisible = ref(false);

    const { form, errors, validate, resetValidation } = useForm({
      email: {
        type: String,
        required: true,
        email: true,
      },
      password: {
        type: String,
        required: true,
      },
      newPassword: {
        type: String,
        required: () => isResetPasswordVisible.value,
      },
      repeatNewPassword: {
        type: String,
        required: () => isResetPasswordVisible.value,
        sameAs: { key: 'newPassword', label: t('changePassword.newPassword') },
      },
      keepMeLoggedIn: {
        type: Boolean,
        required: false,
        default: isKeepMeLoggedInVisible.value ? false : true,
      },
    });

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      try {
        await runLogin(form.email, form.password, form.keepMeLoggedIn, form.newPassword);

        failedLoginCount.value = 0;
        checkLoginBlockedCountdown();
      } catch (error: any) {
        if (error.key === 'passwordResetRequired') {
          resetValidation();
          isResetPasswordVisible.value = true;
          return;
        }
        if (error.key === 'invalidCredentials') {
          failedLoginCount.value += 1;
          checkLoginBlockedCountdown();
        }

        toast.error(error.message);
        return;
      }

      await currentUserStore.fetchCurrentUser();

      const redirectPath = route.query?.red as string;
      const redirectParams = route.query?.qp as string;
      if (redirectPath) {
        router.push({
          path: decodeURIComponent(redirectPath),
          query: redirectParams ? JSON.parse(decodeURIComponent(redirectParams)) : undefined,
        });
        return;
      }

      router.push({ name: RouteKeys.Dashboard });
    };

    const failedLoginCount = ref(getLocalstorage('failedLoginCount') ?? 0);

    const resetLoginBlockedCountdown = () => {
      failedLoginCount.value = 0;
      setLocalstorage('failedLoginCount', failedLoginCount.value);
    };

    const {
      running: isLoginBlocked,
      timer: loginBlockedDuration,
      start: startLoginBlockedTimer,
    } = useCountdown({ callback: resetLoginBlockedCountdown });

    const checkLoginBlockedCountdown = () => {
      if (failedLoginCount.value >= 3) {
        startLoginBlockedTimer(20);
      }

      setLocalstorage('failedLoginCount', failedLoginCount.value);
    };
    checkLoginBlockedCountdown();

    return {
      errors,
      form,
      isKeepMeLoggedInVisible,
      isLoadingCurrentUser,
      isLoggingIn,
      isLoginBlocked,
      isResetPasswordVisible,
      loginBlockedDuration,

      submitForm,

      RouteKeys,
    };
  },
});
