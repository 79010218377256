
import { defineComponent, PropType } from 'vue';

import Badge from '@components/Badge.vue';

import { Log } from '../types';

export default defineComponent({
  components: { Badge },
  props: {
    record: {
      type: Object as PropType<Log>,
      required: true,
    },
  },
});
