
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    pill: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    bounce: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const colorClass = computed(() => {
      return `badge-${props.color}`;
    });

    return { colorClass };
  },
});
