
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import router, { RouteKeys } from '@/router';

import Button from '@components/Button.vue';

import ErrorCode from './ErrorCode.vue';

export default defineComponent({
  props: {
    errorKey: {
      type: String,
      required: true,
    },
  },
  components: { ErrorCode, Button },
  setup(props) {
    const route = useRoute();

    const goBack = () => {
      const steps = route.query?.steps ?? 0;
      if (steps) {
        router.go(-steps);
      } else {
        router.back();
      }
    };
    const goHome = () => {
      router.push({ name: RouteKeys.Dashboard });
    };

    const errorCode = computed(() => {
      const keys: Record<string, number> = {
        forbidden: 403,
        pageNotFound: 404,
      };

      return keys[props.errorKey] as number;
    });

    const isLoggedIn = computed(() => !!localStorage.getItem('user') || !!sessionStorage.getItem('user'));

    return { errorCode, isLoggedIn, goBack, goHome };
  },
});
