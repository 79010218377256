import { Ref, ref } from 'vue';
import { ArticlePartDto, getArticleParts } from '../services';

const result: Ref<ArticlePartDto[]> = ref([]);
const active = ref(false);

export default function useArticleParts() {
  const run = async () => {
    active.value = true;

    result.value = await getArticleParts();

    active.value = false;
  };

  return { result, active, run };
}
