import { ArticlePartListDto } from './article-part-list-dto';

export class ArticleDto {
  id: number;
  number: string;
  name: string;
  extraPrice: number;
  partList: ArticlePartListDto[];

  constructor({ partList, ...rest }: ArticleDto) {
    this.partList = partList.map((item) => new ArticlePartListDto(item));
    Object.assign(this, rest);
  }
}
