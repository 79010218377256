export class ArticlePartDto {
  id: number;
  number: string;
  name: string;
  price: number;
  fixedPrice: boolean;

  constructor(dto: ArticlePartDto) {
    Object.assign(this, dto);
  }
}
