
import { defineComponent } from 'vue';

import { useHead } from '@vueuse/head';

export default defineComponent({
  name: 'App',
  setup() {
    useHead({
      title: process.env.VUE_APP_TITLE,
      meta: [
        {
          name: 'robots',
          content: 'noindex',
        },
      ],
    });
  },
});
