import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-light" }
const _hoisted_2 = {
  class: "container",
  style: {"height":"100vh"}
}
const _hoisted_3 = { class: "card o-hidden border-0 shadow-lg mt-5 mb-3" }
const _hoisted_4 = { class: "card-body p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_grid_col = _resolveComponent("grid-col")!
  const _component_grid_row = _resolveComponent("grid-row")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_grid_row, { classList: "justify-content-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_grid_col, { classList: "col-12 col-xl-10 col-lg-12 col-md-9" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_router_view)
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_Footer, { simple: "" })
    ])
  ]))
}