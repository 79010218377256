import api from '@/custom/api';
import { NotificationGroupDto } from './types';

export async function getNotifications(): Promise<NotificationGroupDto> {
  const response = await api.get('/notifications');
  return new NotificationGroupDto(response.data);
}

export async function markAsSeen(ids: number[]): Promise<void> {
  const response = await api.post('/notifications/mark-as-seen', { notificationIds: ids });
  return response?.data;
}

export async function deleteNotification(id: number): Promise<void> {
  const response = await api.delete(`/notifications/${id}`);
  return response?.data;
}
