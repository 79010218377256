
import { computed, defineComponent, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { debounce } from 'vue-debounce';

import router, { RouteContainers } from '@/router';

import { Role } from '@modules/kernel/enums';
import { useFeatureFlag } from '@modules/kernel/hooks';
import { getCurrentRole } from '@modules/kernel/services';

import { useSidebarStore } from '@modules/layout/stores';

import SidebarDivider from './SidebarDivider.vue';
import SidebarDropdown from './SidebarDropdown.vue';
import SidebarItem from './SidebarItem.vue';

export default defineComponent({
  components: {
    SidebarItem,
    SidebarDivider,
    SidebarDropdown,
  },
  setup() {
    const sidebarStore = useSidebarStore();
    const { isSidebarVisible: showSidebar } = storeToRefs(sidebarStore);
    const { setSidebarVisible } = sidebarStore;

    const { isFlagEnabled } = useFeatureFlag();

    const applicationName = document.title;

    /**
     * AUTO TOGGLE SIDEBAR
     */
    const autoHideSidebar = () => {
      setSidebarVisible(!window.matchMedia('only screen and (max-width: 1199px)').matches);
    };

    onMounted(() => window.addEventListener('resize', debounce(autoHideSidebar, 150)));
    onUnmounted(() => window.removeEventListener('resize', debounce(autoHideSidebar, 150)));
    autoHideSidebar();

    /**
     * ONLY SHOW ALLOWED ROUTES
     */
    const role = getCurrentRole();
    const allowedRoutes = computed(() => {
      const routes = router.options.routes.find((route) => route.name === RouteContainers.Dashboard)?.children ?? [];
      return routes
        .filter((route) => {
          if (!route || !route.name) return false;

          const roles = route?.meta?.roles as Role[] | undefined;
          if (!roles?.length) return true;
          return role && roles.includes(role);
        })
        .map((route) => route.name);
    });

    const isRouteAllowed = (route: string) => allowedRoutes.value.includes(route) && isFlagEnabled(route);

    return { allowedRoutes, applicationName, showSidebar, isRouteAllowed };
  },
});
