import { Ref, ref } from 'vue';
import { getEmptyPageMetadata, PageMetadata, PaginationFilters } from '@modules/kernel/types';
import { ColorSampleOrderDto, getColorSampleOrders } from '../services';

const result: Ref<ColorSampleOrderDto[]> = ref([]);
const metadata: Ref<PageMetadata> = ref(getEmptyPageMetadata());
const active = ref(false);

export default function useColorSampleOrders() {
  const run = async (search?: string, paginationFilters?: PaginationFilters) => {
    active.value = true;

    const response = await getColorSampleOrders(search, paginationFilters);
    result.value = response.items;
    metadata.value = response.page;

    active.value = false;
  };

  return { result, metadata, active, run };
}
