import { AxiosRequestConfig } from 'axios';
import { defineStore } from 'pinia';

type Store = { isSessionExpired: boolean; originalAxiosConfig: AxiosRequestConfig | undefined };

export const useSessionExpiredStore = defineStore('session-expired', {
  state: () => {
    return { isSessionExpired: false, originalAxiosConfig: undefined } as Store;
  },
  actions: {
    setIsSessionExpired(isSessionExpired: boolean) {
      this.isSessionExpired = isSessionExpired;
    },
    setOriginalAxiosConfig(originalAxiosConfig: AxiosRequestConfig | undefined) {
      this.originalAxiosConfig = originalAxiosConfig;
    },
  },
});
