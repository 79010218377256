import { ref } from 'vue';

type UnwrapPromise<T> = T extends PromiseLike<infer U> ? U : T;

export function createAsyncProcess<T extends (...props: any[]) => Promise<any>>(fn: T) {
  const active = ref(false);

  const run = async (...args: Parameters<T>): Promise<UnwrapPromise<ReturnType<T>>> => {
    active.value = true;

    try {
      const result = await fn(...args);

      active.value = false;
      return result;
    } catch (error: any) {
      active.value = false;
      throw error;
    }
  };

  return { active, run };
}
