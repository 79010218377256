import api from '@/custom/api';
import { ImageDto, PageDto } from './types';

export async function getPages(): Promise<PageDto[]> {
  const response = await api.get('/website-images/all-pages');
  return response.data.map((item: any) => new PageDto(item));
}

export async function getImages(page: string): Promise<ImageDto[]> {
  const response = await api.get(`/website-images/${page}`);
  return response.data.map((item: any) => new ImageDto(item));
}

export async function saveImage(key: string, imageId: number): Promise<void> {
  await api.post(`/website-images/${key}`, { imageId });
}

export async function deleteImage(key: string, imageId: number): Promise<void> {
  await api.delete(`/website-images/${key}/${imageId}`);
}
