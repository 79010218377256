/* eslint-disable simple-import-sort/imports */

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import jwtDecode from 'jwt-decode';

import { Role } from '@modules/kernel/enums';
import { useFeatureFlag } from '@modules/kernel/hooks';
import { getCurrentRole } from '@modules/kernel/services/auth-service';

import AuthenticationContainer from '@modules/layout/AuthenticationContainer.vue';
import DashboardContainer from '@modules/layout/DashboardContainer.vue';

import ArticleDetails from '@modules/articles/ArticleDetails.vue';
import ArticleOverview from '@modules/articles/ArticleOverview.vue';
import ArticlePartDetails from '@modules/articleParts/ArticlePartDetails.vue';
import ArticlePartOverview from '@modules/articleParts/ArticlePartOverview.vue';
import ChangePassword from '@modules/users/ChangePassword.vue';
import ClosetCategoryDetails from '@modules/closetCategories/ClosetCategoryDetails.vue';
import ClosetCategoryOverview from '@modules/closetCategories/ClosetCategoryOverview.vue';
import ClosetGripDetails from '@modules/closetGrips/ClosetGripDetails.vue';
import ClosetGripOverview from '@modules/closetGrips/ClosetGripOverview.vue';
import ClosetStructureDetails from '@modules/closetStructures/ClosetStructureDetails.vue';
import ClosetStructureOverview from '@modules/closetStructures/ClosetStructureOverview.vue';
import ColorCategoryDetails from '@modules/colorCategories/ColorCategoryDetails.vue';
import ColorCategoryOverview from '@modules/colorCategories/ColorCategoryOverview.vue';
import ColorDetails from '@modules/colors/ColorDetails.vue';
import ColorOverview from '@modules/colors/ColorOverview.vue';
import ColorSampleOrderDetails from '@modules/colorSampleOrders/ColorSampleOrderDetails.vue';
import ColorSampleOrderOverview from '@modules/colorSampleOrders/ColorSampleOrderOverview.vue';
import ConfirmEmail from '@modules/authentication/ConfirmEmail.vue';
import CustomerDetails from '@modules/customers/CustomerDetails.vue';
import CustomerOverview from '@modules/customers/CustomerOverview.vue';
import Dashboard from '@modules/dashboard/Dashboard.vue';
import Forbidden from '@modules/layout/Forbidden.vue';
import ForgotPassword from '@modules/authentication/ForgotPassword.vue';
import Login from '@modules/authentication/Login.vue';
import Logs from '@modules/logs/Logs.vue';
import NotFound from '@modules/layout/NotFound.vue';
import OrderDetails from '@modules/orders/OrderDetails.vue';
import OrderOverview from '@modules/orders/OrderOverview.vue';
import ResetPassword from '@modules/authentication/ResetPassword.vue';
import Settings from '@modules/settings/Settings.vue';
import UserAccount from '@modules/users/UserAccount.vue';
import UserDetails from '@modules/users/UserDetails.vue';
import UserOverview from '@modules/users/UserOverview.vue';
import WebsiteImages from '@modules/websiteImages/WebsiteImages.vue';
import WebsiteTexts from '@modules/websiteTexts/WebsiteTexts.vue';

export enum RouteKeys {
  ArticleDetails = 'ArticleDetails',
  ArticleOverview = 'ArticleOverview',
  ArticlePartDetails = 'ArticlePartDetails',
  ArticlePartOverview = 'ArticlePartOverview',
  ChangePassword = 'ChangePassword',
  ClosetCategoryDetails = 'ClosetCategoryDetails',
  ClosetCategoryOverview = 'ClosetCategoryOverview',
  ClosetGripDetails = 'ClosetGripDetails',
  ClosetGripOverview = 'ClosetGripOverview',
  ClosetStructureDetails = 'ClosetStructureDetails',
  ClosetStructureOverview = 'ClosetStructureOverview',
  ColorCategoryDetails = 'ColorCategoryDetails',
  ColorCategoryOverview = 'ColorCategoryOverview',
  ColorDetails = 'ColorDetails',
  ColorOverview = 'ColorOverview',
  ColorSampleOrderDetails = 'ColorSampleOrderDetails',
  ColorSampleOrderOverview = 'ColorSampleOrderOverview',
  ConfirmEmail = 'ConfirmEmail',
  CustomerDetails = 'CustomerDetails',
  CustomerOverview = 'CustomerOverview',
  Dashboard = 'Dashboard',
  Forbidden = 'Forbidden',
  ForgotPassword = 'ForgotPassword',
  Login = 'Login',
  Logs = 'Logs',
  NotFound = 'NotFound',
  OrderDetails = 'OrderDetails',
  OrderOverview = 'OrderOverview',
  PublicNotFound = 'PublicNotFound',
  ResetPassword = 'ResetPassword',
  Settings = 'Settings',
  UserAccount = 'UserAccount',
  UserDetails = 'UserDetails',
  UserOverview = 'UserOverview',
  WebsiteImages = 'WebsiteImages',
  WebsiteTexts = 'WebsiteTexts',
}

export enum RouteContainers {
  Authentication = 'AuthenticationContainer',
  Dashboard = 'DashboardContainer',
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: RouteContainers.Dashboard,
    component: DashboardContainer,
    children: [
      {
        path: '/',
        redirect: '/dashboard',
      },
      {
        path: '/dashboard',
        name: RouteKeys.Dashboard,
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/account',
        name: RouteKeys.UserAccount,
        component: UserAccount,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/account/change-password',
        name: RouteKeys.ChangePassword,
        component: ChangePassword,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/settings',
        name: RouteKeys.Settings,
        component: Settings,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/website-images',
        name: RouteKeys.WebsiteImages,
        component: WebsiteImages,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/website-texts',
        name: RouteKeys.WebsiteTexts,
        component: WebsiteTexts,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/users',
        name: RouteKeys.UserOverview,
        component: UserOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/users/:id',
        name: RouteKeys.UserDetails,
        component: UserDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/colors/categories',
        name: RouteKeys.ColorCategoryOverview,
        component: ColorCategoryOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/colors/categories/:id',
        name: RouteKeys.ColorCategoryDetails,
        component: ColorCategoryDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/colors',
        name: RouteKeys.ColorOverview,
        component: ColorOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/colors/:id',
        name: RouteKeys.ColorDetails,
        component: ColorDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/articles/parts',
        name: RouteKeys.ArticlePartOverview,
        component: ArticlePartOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/articles/parts/:id',
        name: RouteKeys.ArticlePartDetails,
        component: ArticlePartDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/articles',
        name: RouteKeys.ArticleOverview,
        component: ArticleOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/articles/:id',
        name: RouteKeys.ArticleDetails,
        component: ArticleDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/closets/categories',
        name: RouteKeys.ClosetCategoryOverview,
        component: ClosetCategoryOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/closets/categories/:id',
        name: RouteKeys.ClosetCategoryDetails,
        component: ClosetCategoryDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/closets/structures',
        name: RouteKeys.ClosetStructureOverview,
        component: ClosetStructureOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/closet/structures/:id',
        name: RouteKeys.ClosetStructureDetails,
        component: ClosetStructureDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/accessories/grips',
        name: RouteKeys.ClosetGripOverview,
        component: ClosetGripOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/accessories/grips/:id',
        name: RouteKeys.ClosetGripDetails,
        component: ClosetGripDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/orders/color-samples',
        name: RouteKeys.ColorSampleOrderOverview,
        component: ColorSampleOrderOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/orders/color-samples/:id',
        name: RouteKeys.ColorSampleOrderDetails,
        component: ColorSampleOrderDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/orders',
        name: RouteKeys.OrderOverview,
        component: OrderOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/orders/:id',
        name: RouteKeys.OrderDetails,
        component: OrderDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/customers',
        name: RouteKeys.CustomerOverview,
        component: CustomerOverview,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/customers/:id',
        name: RouteKeys.CustomerDetails,
        component: CustomerDetails,
        meta: {
          requiresAuth: true,
          roles: [Role.Root, Role.Admin],
        },
      },
      {
        path: '/logs',
        name: RouteKeys.Logs,
        component: Logs,
        meta: {
          requiresAuth: true,
          roles: [Role.Root],
        },
      },
      {
        path: '/404',
        component: NotFound,
        name: RouteKeys.NotFound,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/403',
        component: Forbidden,
        name: RouteKeys.Forbidden,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
      },
    ],
  },
  {
    path: '/',
    name: RouteContainers.Authentication,
    component: AuthenticationContainer,
    children: [
      {
        path: '/login',
        name: RouteKeys.Login,
        component: Login,
        meta: {
          requiresGuest: true,
        },
      },
      {
        path: '/forgot-password',
        name: RouteKeys.ForgotPassword,
        component: ForgotPassword,
        meta: {
          requiresGuest: true,
        },
      },
      {
        path: '/reset-password',
        name: RouteKeys.ResetPassword,
        component: ResetPassword,
        meta: {
          requiresGuest: true,
        },
      },
    ],
  },
  {
    path: '/confirm-email',
    name: RouteKeys.ConfirmEmail,
    component: ConfirmEmail,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/404',
    component: NotFound,
    name: RouteKeys.PublicNotFound,
    meta: {
      requiresGuest: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach((to, from) => {
  const isAuthenticated = hasValidToken();

  if (to.meta.requiresAuth && !isAuthenticated) {
    return {
      name: RouteKeys.Login,
      query: { red: encodeURIComponent(to.path), qp: encodeURIComponent(JSON.stringify(to.query)) },
    };
  }

  if (to.meta.requiresGuest && isAuthenticated) {
    return { name: RouteKeys.Dashboard };
  }

  const role = getCurrentRole();
  const roles = to.meta.roles as Role[] | undefined;
  if (roles?.length && (!role || !roles.includes(role))) {
    return { name: RouteKeys.Forbidden };
  }

  const { isFlagEnabled } = useFeatureFlag();
  const route = to.name as string | undefined;
  if (route && !isFlagEnabled(route)) {
    return { name: RouteKeys.Forbidden };
  }
});

const hasValidToken = () => {
  const token = localStorage.getItem('user') ?? sessionStorage.getItem('user');
  if (!token) return false;

  try {
    const decodedToken = jwtDecode<any>(token);

    const expired = new Date(decodedToken.exp * 1000) < new Date();
    return !expired;
  } catch (error: any) {
    return false;
  }
};

export default router;
