import api from '@/custom/api';
import {
  ClosetSettingsDto,
  DeliverySettingsDto,
  GeneralSettingsDto,
  RootSettingsDto,
  UpdateClosetSettingsDto,
  UpdateDeliverySettingsDto,
  UpdateGeneralSettingsDto,
  UpdateRootSettingsDto,
} from './types';

export async function getGeneralSettings(): Promise<GeneralSettingsDto> {
  const response = await api.get('/settings/general');
  return new GeneralSettingsDto(response.data);
}

export async function saveGeneralSettings(settings: Partial<UpdateGeneralSettingsDto>): Promise<void> {
  const response = await api.patch('/settings/general', settings);
  return response?.data;
}

export async function getRootSettings(): Promise<RootSettingsDto> {
  const response = await api.get('/settings/root');
  return new RootSettingsDto(response.data);
}

export async function saveRootSettings(settings: Partial<UpdateRootSettingsDto>): Promise<void> {
  const response = await api.patch('/settings/root', settings);
  return response?.data;
}

export async function getClosetSettings(): Promise<ClosetSettingsDto> {
  const response = await api.get('/settings/closet');
  return new ClosetSettingsDto(response.data);
}

export async function saveClosetSettings(settings: Partial<UpdateClosetSettingsDto>): Promise<void> {
  const response = await api.patch('/settings/closet', settings);
  return response?.data;
}

export async function getDeliverySettings(): Promise<DeliverySettingsDto> {
  const response = await api.get('/settings/delivery');
  return new DeliverySettingsDto(response.data);
}

export async function saveDeliverySettings(settings: Partial<UpdateDeliverySettingsDto>): Promise<void> {
  const response = await api.patch('/settings/delivery', settings);
  return response?.data;
}
