import { computed, onMounted, onUnmounted, ref } from 'vue';

type Viewport = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const breakpoint = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export function useViewport() {
  const width = ref(0);

  const handleResize = () => {
    width.value = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  };
  handleResize();

  onMounted(() => window.addEventListener('resize', handleResize));
  onUnmounted(() => window.removeEventListener('resize', handleResize));

  const viewport = computed(() => {
    if (width.value <= breakpoint.sm) return 'xs';
    if (width.value <= breakpoint.md) return 'sm';
    if (width.value <= breakpoint.lg) return 'md';
    if (width.value <= breakpoint.xl) return 'lg';
    if (width.value <= breakpoint.xxl) return 'xl';
    return 'xxl';
  });

  const isViewport = (viewport: Viewport): boolean => {
    if (width.value <= breakpoint.sm) return breakpoint.sm > breakpoint[viewport];
    if (width.value <= breakpoint.md) return breakpoint.md > breakpoint[viewport];
    if (width.value <= breakpoint.lg) return breakpoint.lg > breakpoint[viewport];
    if (width.value <= breakpoint.xl) return breakpoint.xl > breakpoint[viewport];
    if (width.value <= breakpoint.xxl) return breakpoint.xxl > breakpoint[viewport];
    return true;
  };

  return { width, viewport, isViewport };
}
