export class UpdateUserDto {
  name: string;
  email: string;
  phone?: string;

  constructor(dto: UpdateUserDto) {
    this.name = dto.name;
    this.email = dto.email;
    this.phone = dto.phone;
  }
}
