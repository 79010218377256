import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormValidationCodeInput = _resolveComponent("FormValidationCodeInput")!
  const _component_grid_col = _resolveComponent("grid-col")!
  const _component_grid_row = _resolveComponent("grid-row")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: "md",
    "is-form-touched": _ctx.isFormTouched,
    onClose: _ctx.closeModal
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('global.validationCode')), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("p", {
        innerHTML: _ctx.$t(_ctx.bodyTranslationkey)
      }, null, 8, _hoisted_1),
      _createVNode(_component_grid_row, { classList: "justify-content-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_grid_col, { classList: "col-12 col-md-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_FormValidationCodeInput, {
                modelValue: _ctx.form.validationCode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.validationCode) = $event)),
                errors: _ctx.errors.validationCode,
                length: 6
              }, null, 8, ["modelValue", "errors"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        color: "secondary",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_Button, {
        color: "primary",
        onClick: _ctx.submitForm,
        disabled: _ctx.isValidating
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('global.continue')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ]),
    _: 1
  }, 8, ["is-form-touched", "onClose"]))
}