
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { dateToString, isToday } from '@utils/date.util';

import Badge from './Badge.vue';

type Notification = {
  id: number;
  title: string;
  message: string;
  icon: string;
  color: string;
  seen: boolean;
  dateAdded: Date;
};

export default defineComponent({
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    },
  },
  components: { Badge },
  setup(props, { emit }) {
    const { t } = useI18n();

    const iconClass = computed(() => {
      return `fa-${props.notification.icon}`;
    });

    const date = computed(() => {
      if (isToday(props.notification.dateAdded)) return t('days.today');
      return dateToString(props.notification.dateAdded);
    });

    const closeNotification = () => {
      emit('close', props.notification.id);
    };

    return { date, iconClass, closeNotification };
  },
});
