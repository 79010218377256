import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { ColorDto, CreateColorDto, UpdateColorDto } from './types';

export async function getColors(
  search?: string,
  paginationFilters?: PaginationFilters
): Promise<PaginatedResponse<ColorDto>> {
  const response = await api.get('/colors', { params: { ...paginationFilters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new ColorDto(item)),
  };
}

export async function getColor(id: number): Promise<ColorDto> {
  const response = await api.get(`/colors/${id}`);
  return new ColorDto(response.data);
}

export async function saveColor(id: number, form: UpdateColorDto): Promise<void> {
  const response = await api.put(`/colors/${id}`, new UpdateColorDto(form));
  return response?.data;
}

export async function createColor(form: CreateColorDto): Promise<void> {
  const response = await api.post('/colors', new CreateColorDto(form));
  return response?.data;
}

export async function deleteColor(id: number): Promise<void> {
  const response = await api.delete(`/colors/${id}`);
  return response?.data;
}
