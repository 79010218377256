import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ad53c724"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { name: _ctx.link },
      class: "nav-link"
    }, {
      default: _withCtx(() => [
        _createElementVNode("i", {
          class: _normalizeClass(["fas fa-fw", _ctx.iconClass])
        }, null, 2),
        _createElementVNode("span", null, _toDisplayString(_ctx.formattedTitle), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}