import axios, { AxiosRequestConfig } from 'axios';
import i18n from '@/i18n';
import router, { RouteKeys } from '@/router';
import { mapObjectPropNames } from '@utils/object.util';
import { camelToSnakeCase, snakeToCamelCase } from '@utils/string.util';
import { useSessionExpiredStore } from '@modules/kernel/stores';

export type CustomError = {
  code: any;
  details: any;
  key: string;
  message: string;
  title: string;
};

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setupInterceptors = () => {
  instance.interceptors.request.use(
    (config) => handleRequest(config),
    (error) => Promise.reject(error)
  );
  instance.interceptors.response.use(
    (res) => handleResponse(res),
    (error) => handleError(error)
  );
};

function handleRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  // add token
  const token = localStorage.getItem('user') ?? sessionStorage.getItem('user');
  if (config?.headers && token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  // map query parameters
  if (config?.params) {
    config.params = mapObjectPropNames(config.params, camelToSnakeCase);
  }

  // map data (body)
  if (config?.data && !(config?.data instanceof FormData)) {
    config.data = mapObjectPropNames(config.data, camelToSnakeCase);
  }

  return config;
}

function handleResponse(response: any): Promise<any> {
  response.data = mapObjectPropNames(response.data, snakeToCamelCase);
  return Promise.resolve(response);
}

function handleError(error: any): Promise<CustomError | void> {
  const { t } = i18n.global;

  const data = error.response?.data;

  let key = snakeToCamelCase(data?.key ?? '');
  const generalErrorKeys = ['unauthorized', 'forbidden', 'notFound'];

  if (!key || generalErrorKeys.includes(key)) {
    key = 'general';
  }

  const translationKey = `errors.${key}`;
  const message = t(translationKey);

  const notFound = error.response?.status === 404;
  if (notFound && error.config?.method === 'get') {
    const token = localStorage.getItem('user') ?? sessionStorage.getItem('user');
    router.push({ name: token ? RouteKeys.NotFound : RouteKeys.PublicNotFound, query: { steps: 2 } });
    return Promise.reject({ ...data, key, translationKey: '', message: '' });
  }

  const tokenExpired = error.config?.url !== '/auth/login' && error.response?.status === 401;
  if (tokenExpired) {
    const sessionExpiredStore = useSessionExpiredStore();
    sessionExpiredStore.setIsSessionExpired(true);
    sessionExpiredStore.setOriginalAxiosConfig(error.config);

    return Promise.reject({ ...data, key, translationKey: '', message: '' });
  }

  return Promise.reject({ ...data, key, translationKey, message });
}

export function shouldRetry(originalConfig?: AxiosRequestConfig): boolean {
  if (!originalConfig) {
    return false;
  }

  // exclude POST /api/users/me if email changed
  if (originalConfig.url === '/users/me' && originalConfig.method === 'put') {
    return false;
  }

  return true;
}

export default instance;
