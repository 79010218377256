import { RouteKeys } from '@/router';
import { stringToBoolean } from '@utils/string.util';

export function useFeatureFlag() {
  const flags: { [key: string]: boolean } = {
    // normal flags
    'remember-login': stringToBoolean(process.env.VUE_APP_REMEMBER_LOGIN),

    // website routes
    [RouteKeys.WebsiteTexts]: stringToBoolean(process.env.VUE_APP_ENABLE_WEBSTE_TEXTS),
    [RouteKeys.WebsiteImages]: stringToBoolean(process.env.VUE_APP_ENABLE_WEBSTE_IMAGES),
  };

  const isFlagEnabled = (route: string, fallback = true): boolean => {
    return flags[route] ?? fallback;
  };

  return { flags, isFlagEnabled };
}
