
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const author = ref('Jinte Michiels');
    const year = ref(new Date().getFullYear());
    const url = computed(() => `https://www.jintemichiels.be?referral=${process.env.VUE_APP_REFERRAL_CODE}`);

    return { author, url, year };
  },
});
