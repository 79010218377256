
import { defineComponent, Ref, ref, shallowRef } from 'vue';

import PageHeader from '@components/layout/PageHeader.vue';

import Tabs, { Tab } from '@components/tabs/Tabs.vue';

import { Role } from '@modules/kernel/enums';
import { getCurrentRole } from '@modules/kernel/services';

import ClosetSettings from './components/ClosetSettings.vue';
import DeliverySettings from './components/DeliverySettings.vue';
import GeneralSettings from './components/GeneralSettings.vue';
import RootSettings from './components/RootSettings.vue';

export default defineComponent({
  components: { PageHeader, Tabs },
  setup() {
    const currentRole = getCurrentRole();

    const tabs: Ref<Tab[]> = ref([
      {
        key: 'general',
        translationKey: 'settings.general',
        component: shallowRef(GeneralSettings),
      },
      {
        key: 'closet',
        translationKey: 'settings.closet',
        component: shallowRef(ClosetSettings),
      },
      {
        key: 'delivery',
        translationKey: 'settings.delivery',
        component: shallowRef(DeliverySettings),
      },
      {
        key: 'root',
        translationKey: 'settings.root',
        component: shallowRef(RootSettings),
        visible: () => currentRole === Role.Root,
      },
    ]);

    return { tabs };
  },
});
