
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formattedTitle = computed(() => {
      return props.title.charAt(0).toUpperCase() + props.title.slice(1);
    });
    const iconClass = computed(() => `fa-${props.icon}`);

    return { formattedTitle, iconClass };
  },
});
