export class CreateUserDto {
  name: string;
  email: string;
  phone?: string;
  role: number;

  constructor(dto: CreateUserDto) {
    this.name = dto.name;
    this.email = dto.email;
    this.phone = dto.phone;
    this.role = dto.role;
  }
}
