import { Ref, ref } from 'vue';
import { ColorDto, getColor } from '../services';

export default function useColor() {
  const result: Ref<ColorDto | undefined> = ref();
  const active = ref(false);

  const run = async (id: number) => {
    active.value = true;

    result.value = await getColor(id);

    active.value = false;
  };

  return { active, result, run };
}
