import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { ColorSampleOrderFilters } from '../types';
import { ColorSampleOrderDto, UpdateColorSampleOrderDto } from './types';

export async function getColorSampleOrders(
  search?: string,
  paginationFilters?: PaginationFilters,
  filters?: ColorSampleOrderFilters
): Promise<PaginatedResponse<ColorSampleOrderDto>> {
  const response = await api.get('/orders/color-samples', { params: { ...paginationFilters, ...filters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new ColorSampleOrderDto(item)),
  };
}

export async function getColorSampleOrder(id: number): Promise<ColorSampleOrderDto> {
  const response = await api.get(`/orders/color-samples/${id}`);
  return new ColorSampleOrderDto(response.data);
}

export async function saveColorSampleOrder(id: number, form: UpdateColorSampleOrderDto): Promise<void> {
  const response = await api.put(`/orders/color-samples/${id}`, new UpdateColorSampleOrderDto(form));
  return response?.data;
}

export async function approveColorSampleOrder(id: number): Promise<void> {
  const response = await api.post(`/orders/color-samples/${id}/approve`);
  return response?.data;
}

export async function rejectColorSampleOrder(id: number): Promise<void> {
  const response = await api.post(`/orders/color-samples/${id}/reject`);
  return response?.data;
}
