import { Language } from '@/i18n';

export class RootSettingsDto {
  defaultLanguage: Language;
  infoLogRetentionPeriod: number;
  warningLogRetentionPeriod: number;
  errorLogRetentionPeriod: number;

  constructor(dto: RootSettingsDto) {
    Object.assign(this, dto);
  }
}
