import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.record.active)
      ? (_openBlock(), _createBlock(_component_Badge, {
          key: 0,
          color: "warning",
          pill: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('user.status.inactive')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.record.emailConfirmed)
      ? (_openBlock(), _createBlock(_component_Badge, {
          key: 1,
          color: "secondary",
          pill: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('user.status.notYetConfirmed')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.record.active && _ctx.record.emailConfirmed)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "/"))
      : _createCommentVNode("", true)
  ], 64))
}