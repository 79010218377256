
import { computed, defineComponent, PropType, ref } from 'vue';

import { matchesRegex } from '@utils/string.util';

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    length: {
      type: Number,
      required: true,
    },
    numeric: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
    // autogrow: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  emits: ['update:modelValue', 'input'],
  setup(props, { emit }) {
    const filteredErrors = computed(() => props.errors.filter((error) => error));

    const growLength = ref(0);
    const actualLength = computed(() => growLength.value + props.length);

    const focusElement = (index: number): boolean => {
      const element = document.getElementById(`${index}`);
      if (element) {
        element.focus();
        return true;
      }

      return false;
    };

    const handleFocus = () => {
      const parts = modelValueParts.value;
      let indexToFocus = parts.length + 1;

      if (focusElement(indexToFocus)) {
        return;
      }

      focusElement(indexToFocus - 1);
    };

    const handleInput = (event: any, index: number) => {
      const input = event.target;
      const parts = modelValueParts.value;

      if (props.numeric && isNaN(input.value)) {
        return;
      }
      if (!matchesRegex(/^[a-zA-Z0-9]*$/, input.value)) {
        return;
      }

      const hasPreviousValue = parts[index - 1] !== undefined;
      const inputValue = `${input.value}`.substring(0, 1).toUpperCase();

      if (inputValue && hasPreviousValue /* && !props.autogrow */) {
        event.target.value = inputValue;
        return;
      }

      if (inputValue && hasPreviousValue /* && props.autogrow */) {
        parts[index - 1] = inputValue;

        const secondInputValue = `${input.value}`.substring(1, 2).toUpperCase();
        parts[index] = secondInputValue;

        growLength.value += 1;
      } else if (inputValue) {
        parts[index - 1] = inputValue;
      } else {
        parts.splice(index - 1, 1);

        if (growLength.value > 0) {
          growLength.value -= 1;
        }
      }

      handleFocus();

      emit('update:modelValue', parts.join('') || '');
      emit('input', parts.join('') || '');
    };

    const handleKeydown = (event: any, index: number) => {
      const input = event.target;
      const parts = modelValueParts.value;

      if (event.key === 'Backspace' && !input.value) {
        parts.splice(index - 2, 1);

        if (growLength.value > 0) {
          growLength.value -= 1;
        }

        handleFocus();
      }

      if (event.key === 'Backspace' && input.value) {
        parts.splice(index - 1, 1);

        if (growLength.value > 0) {
          growLength.value -= 1;
        }

        handleFocus();
      }

      emit('update:modelValue', parts.join('') || '');
      emit('input', parts.join('') || '');
    };

    // generate type based on props
    const inputType = computed(() => {
      if (props.password) return 'password';
      return 'text';
    });

    // split modelValue into an array so it will be easier to fill input fields
    const modelValueParts = computed(() => `${props.modelValue}`.split(''));

    return { actualLength, filteredErrors, inputType, modelValueParts, handleFocus, handleInput, handleKeydown };
  },
});
