
import { defineComponent, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { POSITION, useToast } from 'vue-toastification';

import router, { RouteKeys } from '@/router';
import { useNetwork } from '@vueuse/core';

import Button from '@components/Button.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import FormPassword from '@components/form/FormPassword.vue';
import LoadingSpinner from '@components/LoadingSpinner.vue';
import Modal from '@components/Modal.vue';

import { useForm } from '@modules/kernel/hooks';
import { login, logout } from '@modules/kernel/services';
import { useCurrentUserStore, useSessionExpiredStore } from '@modules/kernel/stores';
import { createAsyncProcess } from '@modules/kernel/utils';

import Footer from './components/Footer.vue';
import Header from './components/Header.vue';
import Sidebar from './components/Sidebar.vue';

export default defineComponent({
  components: {
    Header,
    Sidebar,
    Footer,
    LoadingSpinner,
    Modal,
    Button,
    FormItemWrapper,
    FormPassword,
  },
  setup() {
    const toast = useToast();
    const { t } = useI18n();
    const { downlink, isOnline } = useNetwork();

    const { active: isLoggingIn, run: runLogin } = createAsyncProcess(login);

    const currentUserStore = useCurrentUserStore();
    const { currentUser } = storeToRefs(currentUserStore);

    const sessionExpiredStore = useSessionExpiredStore();
    const { isSessionExpired } = storeToRefs(sessionExpiredStore);

    /**
     * FETCH CURRENT USER ON LOAD
     */
    const fetchCurrentUserOrLogout = async () => {
      try {
        await currentUserStore.fetchCurrentUser();
      } catch (error: any) {
        logout();
        router.push({ name: RouteKeys.Login });
      }
    };

    if (!currentUser.value) {
      fetchCurrentUserOrLogout();
    }

    /**
     * LOGIN WHEN TOKEN EXPIRED
     */
    const { form, errors, validate } = useForm({
      password: {
        type: String,
        required: true,
      },
    });

    const submitLogin = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      try {
        await runLogin(currentUser.value!.email, form.password);
        await currentUserStore.fetchCurrentUser();
        sessionExpiredStore.setIsSessionExpired(false);

        sessionExpiredStore.setOriginalAxiosConfig(undefined);
        toast.info(t('authentication.successfullyLoggedInAgain.repeatAction'));
      } catch (error: any) {
        if (error.key === 'errors.invalidCredentials') {
          toast.error(t('errors.incorrectPassword'));
        } else {
          toast.error(error.message);
        }
      }
    };

    /**
     * Internet connection notifications
     */
    watch(isOnline, () => {
      toast.dismiss('restored-toast');
      toast.dismiss('offline-toast');

      if (isOnline.value) {
        toast.success(t('network.restored'), {
          id: 'restored-toast',
          position: POSITION.TOP_CENTER,
          bodyClassName: 'text-center',
          closeButton: false,
        });
      } else {
        toast.error(t('network.offline'), {
          id: 'offline-toast',
          position: POSITION.TOP_CENTER,
          bodyClassName: 'text-center',
          closeButton: false,
        });
      }
    });

    watch(downlink, () => {
      if (downlink.value && downlink.value > 0.75) {
        toast.dismiss('slow-toast');
      }

      if (downlink.value && downlink.value < 0.5) {
        toast.warning(t('network.slow'), {
          id: 'slow-toast',
          position: POSITION.TOP_CENTER,
          bodyClassName: 'text-center',
          closeButton: false,
        });
      }
    });

    return { currentUser, errors, form, isLoggingIn, isSessionExpired, submitLogin };
  },
});
