import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { ClosetStructureDto, CreateClosetStructureDto, UpdateClosetStructureDto } from './types';

export async function getClosetStructures(
  search?: string,
  paginationFilters?: PaginationFilters
): Promise<PaginatedResponse<ClosetStructureDto>> {
  const response = await api.get('/closet-structures', { params: { ...paginationFilters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new ClosetStructureDto(item)),
  };
}

export async function getClosetStructure(id: number): Promise<ClosetStructureDto> {
  const response = await api.get(`/closet-structures/${id}`);
  return new ClosetStructureDto(response.data);
}

export async function saveClosetStructure(id: number, form: UpdateClosetStructureDto): Promise<void> {
  const response = await api.put(`/closet-structures/${id}`, new UpdateClosetStructureDto(form));
  return response?.data;
}

export async function createClosetStructure(form: CreateClosetStructureDto): Promise<void> {
  const response = await api.post('/closet-structures', new CreateClosetStructureDto(form));
  return response?.data;
}

export async function deleteClosetStructure(id: number): Promise<void> {
  const response = await api.delete(`/closet-structures/${id}`);
  return response?.data;
}
