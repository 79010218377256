
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import i18n, { Language } from '@/i18n';

import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';

import Button from '@components/Button.vue';
import FormDropdown, { Option } from '@components/form/FormDropdown.vue';
import FormInput from '@components/form/FormInput.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import LoadingSpinner from '@components/LoadingSpinner.vue';

import { useForm } from '@modules/kernel/hooks';
import { createAsyncProcess } from '@modules/kernel/utils';

import { getGeneralSettings, saveGeneralSettings } from '../services';

export default defineComponent({
  components: { GridRow, GridCol, FormItemWrapper, FormDropdown, Button, LoadingSpinner, FormInput },
  setup() {
    const { t } = useI18n();
    const toast = useToast();

    const { active: isLoadingSettings, run: runGetSettings } = createAsyncProcess(getGeneralSettings);
    const { active: isSavingSettings, run: runSaveSettings } = createAsyncProcess(saveGeneralSettings);

    const { form, errors, validate } = useForm({
      language: {
        type: String,
        required: true,
        default: Language.Dutch,
      },
      notificationEmail: {
        type: String,
        required: true,
        default: '',
      },
    });

    const languageOptions: Option[] = [
      { value: Language.English, translationKey: 'language.english' },
      { value: Language.Dutch, translationKey: 'language.dutch' },
    ];

    const getSettings = async () => {
      try {
        const settings = await runGetSettings();
        Object.assign(form, settings);
      } catch (error: any) {
        toast.error(error.message);
      }
    };
    getSettings();

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      try {
        await runSaveSettings(form);
        i18n.global.locale = form.language;
        toast.success(t('global.changesSaved'));
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    return { errors, form, isLoadingSettings, isSavingSettings, languageOptions, submitForm };
  },
});
