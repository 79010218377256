/* eslint-disable simple-import-sort/imports */

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@vueuse/head';
import App from './App.vue';
import router from './router';

// translation package
import i18n from './i18n';

// pinia store
const pinia = createPinia();

// axios api
import { setupInterceptors } from './custom/api';
setupInterceptors();

// toast notifications
import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const toastOptions: PluginOptions = {
  icon: '',
  position: POSITION.BOTTOM_CENTER,
  filterBeforeCreate: (toast) => (toast.content !== '' ? toast : false),
};

// tooltips
import 'floating-vue/dist/style.css';

// custom additions to vue
import clickOutside from './custom/click-outside';

// custom fonts
import './assets/js/fontawesome-b25c3db3e3.js';
import './assets/css/googlefont-nunito.css';

// custom css
import './assets/css/sb-admin-2.css';
import './assets/css/toast.css';
import './assets/css/password-meter.css';
import './assets/css/tooltip.css';
import './assets/css/custom.css';

// head & meta
const head = createHead();

createApp(App)
  .directive('click-outside', clickOutside)
  .use(router)
  .use(i18n)
  .use(pinia)
  .use(Toast, toastOptions)
  .use(head)
  .mount('#app');
