import api from '@/custom/api';
import { AuthResponseDto } from '@modules/kernel/services';

export async function confirmEmail(token: string): Promise<AuthResponseDto | undefined> {
  const response = await api.post('auth/confirm-email', { token });

  if (response?.data) {
    sessionStorage.setItem('user', response.data.token);
  }

  return response.data ? new AuthResponseDto(response.data) : undefined;
}

export async function forgotPassword(email: string): Promise<void> {
  const response = await api.post('auth/forgot-password', { email });
  return response?.data;
}

export async function resetPassword(token: string, newPassword: string): Promise<AuthResponseDto | undefined> {
  const response = await api.post('auth/reset-password', { token, newPassword });

  if (response?.data) {
    sessionStorage.setItem('user', response.data.token);
  }

  return response.data ? new AuthResponseDto(response.data) : undefined;
}
