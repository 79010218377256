import { ColorCategoryDto } from './color-category-dto';

export class ColorDto {
  id: number;
  colorCategory: ColorCategoryDto;
  name: string;
  colorCode?: string;
  imageId?: number;
  imageUrl?: string;

  constructor({ colorCategory, ...rest }: ColorDto) {
    this.colorCategory = new ColorCategoryDto(colorCategory);
    Object.assign(this, rest);
  }
}
