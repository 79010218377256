export class CustomerDto {
  id: number;
  name: string;
  companyName?: string;
  companyVat?: string;
  phone?: string;
  email: string;
  street?: string;
  postalCode?: string;
  city?: string;

  constructor(dto: CustomerDto) {
    Object.assign(this, dto);
  }
}
