import { useI18n } from 'vue-i18n';

type Translator = (key: string, params?: string[]) => string;

export function getEmailError(input: any, translator?: Translator): string | undefined {
  if (!translator) {
    translator = useI18n().t;
  }

  if (input.$dirty && input.email?.$invalid) {
    return translator('formValidation.emailInvalid');
  }
  return undefined;
}

export function getRequiredError(input: any, translator?: Translator): string | undefined {
  if (!translator) {
    translator = useI18n().t;
  }

  if (input.$dirty && input.required?.$invalid) {
    return translator('formValidation.inputRequired');
  }
  return undefined;
}

export function getSameAsError(input: any, propertyName: string, translator?: Translator): string | undefined {
  if (!translator) {
    translator = useI18n().t;
  }

  if (input.$model && input.$dirty && input.sameAs?.$invalid) {
    return translator('formValidation.sameAs', [propertyName.toLowerCase()]);
  }
  return undefined;
}

export function getMinLengthError(input: any, translator?: Translator): string | undefined {
  if (!translator) {
    translator = useI18n().t;
  }

  if (input.$model && input.$dirty && input.minLength?.$invalid) {
    return translator('formValidation.minLength', [input.minLength.$params.min]);
  }
  return undefined;
}

export function getMinValueError(input: any, translator?: Translator): string | undefined {
  if (!translator) {
    translator = useI18n().t;
  }

  if (input.$model != null && input.$dirty && input.minValue?.$invalid) {
    return translator('formValidation.minValue', [input.minValue.$params.min]);
  }
  return undefined;
}

export function getMaxLengthError(input: any, translator?: Translator): string | undefined {
  if (!translator) {
    translator = useI18n().t;
  }

  if (input.$model && input.$dirty && input.maxLength?.$invalid) {
    return translator('formValidation.maxLength', [input.maxLength.$params.max]);
  }
  return undefined;
}

export function getMaxValueError(input: any, translator?: Translator): string | undefined {
  if (!translator) {
    translator = useI18n().t;
  }

  if (input.$model != null && input.$dirty && input.maxValue?.$invalid) {
    return translator('formValidation.maxValue', [input.maxValue.$params.max]);
  }
  return undefined;
}
