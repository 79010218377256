export class UpdateArticlePartDto {
  number: string;
  name: string;
  price: number;
  fixedPrice: boolean;

  constructor(dto: UpdateArticlePartDto) {
    this.number = dto.number;
    this.name = dto.name;
    this.price = dto.price;
    this.fixedPrice = dto.fixedPrice;
  }
}
