export type PaginatedResponse<T> = {
  page: PageMetadata;
  items: Array<T>;
};

export type PageMetadata = {
  number: number;
  length: number;
  size: number;
  totalItems: number;
  totalPages: number;
};

export function getEmptyPageMetadata(): PageMetadata {
  return {
    number: 0,
    length: 0,
    size: 0,
    totalItems: 0,
    totalPages: 0,
  };
}

export type PaginationFilters = {
  page: number;
  length: number;
};
