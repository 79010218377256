
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import router, { RouteKeys } from '@/router';

import Button from './Button.vue';

export default defineComponent({
  components: { Button },
  props: {
    fallback: {
      type: Object as PropType<RouteKeys | RouteLocationRaw>,
      required: false,
    },
  },
  setup(props) {
    const historyAvailable = router.options.history.state.back !== null;

    const goBack = () => {
      if (historyAvailable) router.back();

      if (typeof props.fallback === 'string') {
        router.push({ name: props.fallback });
      } else if (typeof props.fallback === 'object') {
        router.push(props.fallback);
      } else {
        router.push({ name: RouteKeys.Dashboard });
      }
    };

    return { goBack };
  },
});
