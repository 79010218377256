import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: "#",
    class: _normalizeClass(["nav-item nav-link", { active: _ctx.active }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["prevent"]))
  }, _toDisplayString(_ctx.tab.translationKey ? _ctx.$t(_ctx.tab.translationKey) : _ctx.tab.label ?? ''), 3))
}