import { Ref, ref } from 'vue';
import { ClosetSettingsDto, getClosetSettings } from '../services';

const result: Ref<ClosetSettingsDto> = ref({
  maxAmountOfSamples: 0,
  singleMinWidth: 0,
  singleMaxWidth: 0,
  singleDefaultWidth: 0,
  singleMinHeight: 0,
  singleMaxHeight: 0,
  singleDefaultHeight: 0,
  singleMinDepth: 0,
  singleMaxDepth: 0,
  singleDefaultDepth: 0,
  doubleMinWidth: 0,
  doubleMaxWidth: 0,
  doubleDefaultWidth: 0,
  doubleMinHeight: 0,
  doubleMaxHeight: 0,
  doubleDefaultHeight: 0,
  doubleMinDepth: 0,
  doubleMaxDepth: 0,
  doubleDefaultDepth: 0,
  defaultExtraPrice: 0,
  customizationOptions: [],
});
const active = ref(false);

export default function useClosetSettings() {
  const run = async () => {
    active.value = true;

    result.value = await getClosetSettings();

    active.value = false;
  };

  return { result, active, run };
}
