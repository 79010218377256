export class UpdateCurrentUserDto {
  name: string;
  email: string;
  phone?: string;
  validationCode?: string;

  constructor(dto: UpdateCurrentUserDto) {
    this.name = dto.name;
    this.email = dto.email;
    this.phone = dto.phone;
    this.validationCode = dto.validationCode;
  }
}
