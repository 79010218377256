export function useLocalstorage() {
  const get = (key: string) => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  };

  const set = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  return { getLocalstorage: get, setLocalstorage: set };
}
