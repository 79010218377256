import { Ref, ref } from 'vue';
import { getImages, ImageDto } from '../services';

export default function useImages() {
  const result: Ref<ImageDto[]> = ref([]);
  const active = ref(false);

  const run = async (page: string) => {
    active.value = true;

    result.value = await getImages(page);

    active.value = false;
  };

  return { result, active, run };
}
