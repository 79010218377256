import { LogDto } from '../services';

export interface Log {
  id: number;
  type: string;
  description: string;
  objectBefore: any;
  objectAfter: any;
  path: string;
  highPriority: boolean;
  new: boolean;
  seen: boolean;
  userId: number;
  userName: string;
  dateAdded: Date;
}

export function mapDtoToLog(dto: LogDto): Log {
  return {
    id: dto.id,
    type: dto.type,
    description: dto.description,
    objectBefore: dto.objectBefore,
    objectAfter: dto.objectAfter,
    path: dto.path,
    highPriority: dto.highPriority,
    new: !dto.seen,
    seen: dto.seen,
    userId: dto.user.id,
    userName: dto.user.name,
    dateAdded: dto.dateAdded,
  };
}
