
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    block: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md',
    },
  },
  setup(props) {
    const typeClass = computed(() => {
      if (props.link) return `text-${props.color}`;
      return `btn-${props.outline ? 'outline-' : ''}${props.color}`;
    });
    const sizeClass = computed(() => `btn-${props.size}`);

    return { sizeClass, typeClass };
  },
});
