
import { defineComponent } from 'vue';

import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';

import Button from '@components/Button.vue';
import FormValidationCodeInput from '@components/form/FormValidationCodeInput.vue';
import Modal from '@components/Modal.vue';

import { useForm } from '@modules/kernel/hooks';

export default defineComponent({
  components: { Modal, Button, FormValidationCodeInput, GridRow, GridCol },
  props: {
    bodyTranslationkey: {
      type: String,
      required: true,
    },
    isValidating: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'confirm'],
  setup(_, { emit }) {
    const {
      form,
      errors,
      validate,
      isTouched: isFormTouched,
    } = useForm({
      validationCode: {
        type: String,
        required: true,
      },
    });

    const closeModal = () => {
      emit('close');
    };

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      emit('confirm', form.validationCode);
    };

    return { errors, form, isFormTouched, closeModal, submitForm };
  },
});
