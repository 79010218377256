
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    errors: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'input'],
  setup(props, { emit }) {
    const filteredErrors = computed(() => props.errors.filter((error) => error));
    const handleInput = (event: any) => {
      let value = event.target.value;
      if (props.type === 'number' && value) {
        value = +value;
      }

      emit('update:modelValue', value ?? null);
      emit('input', value ?? null);
    };

    return { filteredErrors, handleInput };
  },
});
