import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2832f66d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "value", "placeholder", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: _ctx.type,
    value: _ctx.modelValue,
    class: _normalizeClass(["form-control", { 'is-invalid': _ctx.filteredErrors.length }]),
    placeholder: _ctx.placeholder,
    readonly: _ctx.readonly,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
  }, null, 42, _hoisted_1))
}