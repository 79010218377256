import { parseDate } from '@utils/date.util';

export class LogDto {
  id: number;
  type: string;
  description: string;
  objectBefore?: any;
  objectAfter?: any;
  path: string;
  highPriority: boolean;
  seen: boolean;
  user: UserDto;
  dateAdded: Date;

  constructor({ user, dateAdded, ...rest }: LogDto) {
    this.user = new UserDto(user);
    this.dateAdded = parseDate(dateAdded)!;
    Object.assign(this, rest);
  }
}

class UserDto {
  id: number;
  name: string;

  constructor(dto: UserDto) {
    Object.assign(this, dto);
  }
}
