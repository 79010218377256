import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { ClosetCategoryDto, CreateClosetCategoryDto, UpdateClosetCategoryDto } from './types';

export async function getClosetCategories(
  search?: string,
  paginationFilters?: PaginationFilters
): Promise<PaginatedResponse<ClosetCategoryDto>> {
  const response = await api.get('/closet-categories', { params: { ...paginationFilters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new ClosetCategoryDto(item)),
  };
}

export async function getClosetCategory(id: number): Promise<ClosetCategoryDto> {
  const response = await api.get(`/closet-categories/${id}`);
  return new ClosetCategoryDto(response.data);
}

export async function saveClosetCategory(id: number, form: UpdateClosetCategoryDto): Promise<void> {
  const response = await api.put(`/closet-categories/${id}`, new UpdateClosetCategoryDto(form));
  return response?.data;
}

export async function createClosetCategory(form: CreateClosetCategoryDto): Promise<void> {
  const response = await api.post('/closet-categories', new CreateClosetCategoryDto(form));
  return response?.data;
}

export async function deleteClosetCategory(id: number): Promise<void> {
  const response = await api.delete(`/closet-categories/${id}`);
  return response?.data;
}
