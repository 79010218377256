
import { defineComponent } from 'vue';

import ConfirmModal from './ConfirmModal.vue';

export default defineComponent({
  components: { ConfirmModal },
  props: {
    bodyTranslationkey: {
      type: String,
      required: true,
    },
    askPassword: {
      type: Boolean,
      default: false,
    },
    isDeleting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'confirm'],
  setup(_, { emit }) {
    const closeModal = () => {
      emit('close');
    };

    const confirmDelete = async (password?: string) => {
      emit('confirm', password);
    };

    return { closeModal, confirmDelete };
  },
});
