
import { computed, defineComponent, PropType } from 'vue';
import { Tooltip } from 'floating-vue';

import FormError from './FormError.vue';
import FormGroup from './FormGroup.vue';
import FormLabel from './FormLabel.vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    errors: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
  },
  components: { FormGroup, FormLabel, FormError, Tooltip },
  setup(props) {
    const filteredErrors = computed(() => props.errors.filter((error) => error));

    return { filteredErrors };
  },
});
