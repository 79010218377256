export class DeliverySettingsDto {
  deliveryPricePerKilometer: number;
  deliveryFreeKilometers: number;
  deliveryMaxKilometers: number;
  deliveryCostStreet: string;
  deliveryCostCity: string;
  deliveryCostPostalCode: string;

  constructor(dto: DeliverySettingsDto) {
    Object.assign(this, dto);
  }
}
