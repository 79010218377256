import { defineStore } from 'pinia';
import { CurrentUserDto, getCurrentUser } from '@modules/kernel/services';

type Store = { currentUser: CurrentUserDto | undefined; isLoadingCurrentUser: boolean };

export const useCurrentUserStore = defineStore('current-user', {
  state: () => {
    return { currentUser: undefined, isLoadingCurrentUser: false } as Store;
  },
  actions: {
    setCurrentUser(user: CurrentUserDto | undefined) {
      this.currentUser = user;
    },
    async fetchCurrentUser() {
      this.isLoadingCurrentUser = true;

      const currentUser = await getCurrentUser();

      this.isLoadingCurrentUser = false;
      this.setCurrentUser(currentUser);
    },
  },
});
