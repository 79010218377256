
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    grow: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    classList: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const spinnerClass = computed(() => {
      const base = `spinner-${props.grow ? 'grow' : 'border'}`;
      const size = props.small ? `${base}-sm` : '';
      return `${base} ${size}`;
    });

    return { spinnerClass };
  },
});
