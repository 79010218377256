
import { computed, defineComponent, PropType } from 'vue';

import Badge from '@components/Badge.vue';

import { Log } from '../types';

export default defineComponent({
  components: { Badge },
  props: {
    record: {
      type: Object as PropType<Log>,
      required: true,
    },
  },
  setup(props) {
    const type = computed(() => {
      if (props.record.type === 'error') return 'danger';
      if (props.record.type === 'warning') return 'warning';
      return 'secondary';
    });

    return { type };
  },
});
