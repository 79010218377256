
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue';
import { Tooltip } from 'floating-vue';
import { debounce } from 'vue-debounce';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import { RouteKeys } from '@/router';

import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';

import Button from '@components/Button.vue';
import ButtonGroup from '@components/ButtonGroup.vue';
import FormInput from '@components/form/FormInput.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import LoadingSpinner from '@components/LoadingSpinner.vue';

import { DateFormat, dateToString } from '@utils/date.util';
import { formatCurrency } from '@utils/number.util';

import { useViewport } from '@modules/kernel/hooks';
import { PageMetadata } from '@modules/kernel/types';

export type Column = {
  key: string;
  label?: string;
  translationKey?: string;
  type: ColumnType;
  widthPercentage?: number;
  widthPixels?: number;
  actions?: ColumnAction[];
  format?: DateFormat;
  component?: any;
  valutaSymbol?: string;
  visible?: () => boolean;
  tooltip?: (record: any) => string | undefined;
};

export type ColumnAction = {
  event?: string;
  link?: (record: any) => { name: RouteKeys; params?: any };
  icon: string;
  color?: string;
  visible?: (record: any) => boolean;
  tooltip?: (record: any) => string;
  disabled?: (record: any) => boolean;
};

export enum ColumnType {
  String = 'string',
  Boolean = 'boolean',
  Actions = 'actions',
  Date = 'date',
  Component = 'component',
  Valuta = 'valuta',
}

export default defineComponent({
  components: { GridRow, GridCol, Button, ButtonGroup, LoadingSpinner, Tooltip, FormItemWrapper, FormInput },
  props: {
    columns: {
      type: Array as PropType<Array<Column>>,
      required: true,
    },
    records: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    serverPaginated: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 25,
    },
    search: {
      type: Boolean,
      default: false,
    },
    serverSearch: {
      type: Boolean,
      default: false,
    },
    updateUrl: {
      type: Boolean,
      default: false,
    },
    pageMetadata: {
      type: Object as PropType<PageMetadata>,
      required: false,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    enableFilters: {
      type: Boolean,
      default: true,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    enableDownload: {
      type: Boolean,
      default: true,
    },
    downloadTooltip: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const { isViewport } = useViewport();

    const searchClassList = computed(() => `${isViewport('sm') ? '' : 'col-12'} pb-0`);
    const filterClassList = computed(
      () => `${props.showDownload ? 'col-8' : 'col-12'} col-sm-4 col-md-3 col-lg-2 pl-sm-0`
    );
    const downloadClassList = computed(
      () => `${props.showFilters ? 'col-4 pl-0' : 'col-12'} col-sm-2 col-md-1 pl-sm-0`
    );

    /**
     * COLUMNS
     */
    const visibleColumns = computed(() => props.columns.filter((col) => (col.visible ? col.visible() ?? true : true)));
    const columnType = ColumnType;

    /**
     * PAGINATION
     */
    const page = ref(1);

    const lastPage = computed(() => {
      if (props.serverPaginated) {
        return props.pageMetadata?.totalPages ?? 1;
      }
      return Math.ceil(props.records.length / props.size);
    });
    watch(lastPage, () => {
      if (page.value > lastPage.value) {
        page.value = 1;
        updateQuery();
      }
    });

    const handlePaginate = (key: 'first' | 'previous' | 'next' | 'last' | number) => {
      if (key === 'first') {
        page.value = 1;
      }
      if (key === 'previous') {
        page.value = Math.max(1, page.value - 1);
      }
      if (key === 'next') {
        page.value = Math.min(lastPage.value, page.value + 1);
      }
      if (key === 'last') {
        page.value = lastPage.value;
      }

      if (typeof key === 'number') {
        page.value = key;
      }

      emit('onPaginate', { search: searchCriteria.value, paginationFilters: { page: page.value, length: props.size } });
      updateQuery();
    };

    const paginationInfo = computed(() => {
      if (!props.paginated && !props.serverPaginated) {
        return '';
      }

      if (props.serverPaginated) {
        if (!props.pageMetadata) {
          return '';
        }

        const pageStart = (props.pageMetadata.number - 1) * props.pageMetadata.length + 1;

        let pageEnd = (props.pageMetadata.number - 1) * props.pageMetadata.length + props.pageMetadata.length;
        if (pageEnd > props.pageMetadata.totalItems) pageEnd = props.pageMetadata.totalItems;

        return t('table.pagination.summary', [`${pageStart} - ${pageEnd}`, props.pageMetadata.totalItems]);
      }

      const total = props.records.length;
      if (total > 0) {
        const pageStart = (page.value - 1) * props.size + 1;
        const pageEnd = Math.min(total, page.value * props.size);
        return t('table.pagination.summary', [`${pageStart} - ${pageEnd}`, total]);
      }

      return t('table.pagination.summary', [0, 0]);
    });

    /**
     * SEARCH
     */
    const searchCriteria: Ref<string | undefined> = ref(undefined);
    const handleSearch = (search: string) => {
      emit('onSearch', { search });
      updateQuery();
    };
    const debouncedHandleSearch = debounce(handleSearch, 500);

    /**
     * WATCH URL TO SET PAGE & SEARCH FROM URL ON LOAD
     */
    watch(route, () => {
      if (route.query?.search && route.query.search !== searchCriteria.value) {
        searchCriteria.value = route.query.search.toString();
        debouncedHandleSearch(searchCriteria.value);
      }
      if (route.query?.page && +route.query.page !== page.value) {
        page.value = +route.query.page;
        handlePaginate(page.value);
      }
    });
    onMounted(() => {
      if (route.query?.search && route.query.search !== searchCriteria.value) {
        searchCriteria.value = route.query.search.toString();
        debouncedHandleSearch(searchCriteria.value);
      }
      if (route.query?.page && +route.query.page !== page.value) {
        page.value = +route.query.page;
        handlePaginate(page.value);
      }
    });

    /**
     * HANDLE FILTERING
     */
    const toggleFilters = () => {
      emit('onToggleFilters');
    };

    /**
     * HANDLE DOWNLOAD
     */
    const handleDownload = () => {
      emit('onDownload');
    };

    /**
     * RECORD HANDLING
     */
    const filteredRecords = computed(() => {
      let records = props.records;

      if (props.search && searchCriteria.value) {
        records = records.filter((record) => {
          return visibleColumns.value
            .filter((col) => col.type === ColumnType.String)
            .some((col) => {
              const value = record[col.key];

              if (value === undefined) return false;
              return `${value}`.toLowerCase().includes(searchCriteria.value?.toLowerCase() ?? '');
            });
        });
      }

      if (props.paginated) {
        return records.slice((page.value - 1) * props.size, page.value * props.size);
      }
      return records;
    });

    const sendEvent = (event: string | undefined, data: any) => {
      if (event) {
        emit(event, data);
      }
    };

    const formatDate = (date: Date, format: DateFormat) => {
      return dateToString(date, format);
    };

    const formatValuta = (value: number, symbol?: string) => {
      const actualSymbol = symbol ?? '€';

      if (value === Math.floor(value)) {
        return `${actualSymbol} ${value}`;
      }
      return formatCurrency(value, actualSymbol);
    };

    const calculateWidth = (col: Column) => {
      if (col.widthPixels) return `${col.widthPixels}px`;
      if (col.widthPercentage) return `${col.widthPercentage}%`;
      return '';
    };

    const showActionButton = (action: ColumnAction, record: any) => {
      const visible = action.visible !== undefined ? action.visible(record) : true;
      return visible && action.event;
    };

    const showActionLink = (action: ColumnAction, record: any) => {
      const visible = action.visible !== undefined ? action.visible(record) : true;
      return visible && action.link;
    };

    const generateRecordStyle = (record: any) => {
      if (!record.rowColor) return '';
      return `background-color: ${record.rowColor}1A;`; // 1A = 10% opacity
    };

    const updateQuery = () => {
      if (!props.updateUrl) return;

      const query: any = {};

      if ((props.search || props.serverSearch) && searchCriteria.value) {
        query.search = searchCriteria.value;
      }
      if ((props.paginated || props.serverPaginated) && page.value !== 1) {
        query.page = page.value;
      }

      router.replace({ name: route.name!, query });
    };

    /**
     * INTERFACE METHODS
     */
    const sendFilters = () => {
      emit('onPaginate', {
        search: searchCriteria.value,
        paginationFilters: { page: page.value, length: props.size },
      });
    };

    onMounted(() => {
      emit('interface', { sendFilters });
    });

    return {
      columnType,
      downloadClassList,
      filterClassList,
      filteredRecords,
      lastPage,
      page,
      paginationInfo,
      searchClassList,
      searchCriteria,
      visibleColumns,

      calculateWidth,
      debouncedHandleSearch,
      formatDate,
      formatValuta,
      generateRecordStyle,
      handleDownload,
      handlePaginate,
      isViewport,
      sendEvent,
      showActionButton,
      showActionLink,
      toggleFilters,
    };
  },
});
