
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';

import Button from '@components/Button.vue';
import FormInput from '@components/form/FormInput.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import FormTextarea from '@components/form/FormTextarea.vue';

import { useForm } from '@modules/kernel/hooks';
import { createAsyncProcess } from '@modules/kernel/utils';

import { saveTexts, TextDto } from '../services';

export default defineComponent({
  components: { GridRow, GridCol, Button, FormItemWrapper, FormInput, FormTextarea },
  props: {
    page: {
      type: String,
      required: true,
    },
    texts: {
      type: Object as PropType<TextDto[]>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const toast = useToast();

    const { active: isSavingTexts, run: runSaveTexts } = createAsyncProcess(saveTexts);

    const formSetup = (props.texts ?? []).reduce((previous, item) => {
      return {
        ...previous,
        [item.key]: {
          type: String,
          default: item.value,
          required: false,
        },
      };
    }, {} as any);

    const { form, errors, validate } = useForm(formSetup);

    const submitForm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      try {
        await runSaveTexts(props.page, form);
        toast.success(t('global.changesSaved'));
      } catch (error: any) {
        toast.error(error.message);
      }
    };

    return {
      errors,
      form,
      isSavingTexts,

      submitForm,
    };
  },
});
