export class ImageDto {
  id: number;
  label: string;
  key: string;
  amount: number;
  images: ActualImageDto[];

  constructor(dto: ImageDto) {
    Object.assign(this, dto);
  }
}

class ActualImageDto {
  id: number;
  url: string;

  constructor(dto: ActualImageDto) {
    Object.assign(this, dto);
  }
}
