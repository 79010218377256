export class ColorDto {
  id: number;
  name: string;
  colorCode?: string;
  imageId?: number;
  imageUrl?: string;

  constructor(dto: ColorDto) {
    Object.assign(this, dto);
  }
}
