import jwtDecode from 'jwt-decode';
import api from '@/custom/api';
import { Role } from '../enums';
import { AuthResponseDto } from './types';

export async function login(
  email: string,
  password: string,
  keepMeLoggedIn?: boolean,
  newPassword?: string
): Promise<AuthResponseDto> {
  const response = await api.post('/auth/login', { email, password, newPassword });

  if (response?.data) {
    // remove old token
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');

    // set new token
    if (keepMeLoggedIn) {
      localStorage.setItem('user', response.data.token);
    } else {
      sessionStorage.setItem('user', response.data.token);
    }
  }

  return new AuthResponseDto(response.data);
}

export function logout(): void {
  localStorage.removeItem('user');
  sessionStorage.removeItem('user');
}

export function getCurrentRole(): Role | undefined {
  const token = localStorage.getItem('user') ?? sessionStorage.getItem('user');
  if (!token) return undefined;

  try {
    const decodedToken = jwtDecode<any>(token);
    return decodedToken.role as Role;
  } catch (error: any) {
    return undefined;
  }
}
