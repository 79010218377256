import api from '@/custom/api';
import { LogDto } from './types';

export async function getLogs(search?: string): Promise<LogDto[]> {
  const response = await api.get('/logs', { params: { search } });
  return response.data.map((item: any) => new LogDto(item));
}

export async function markAsSeen(ids: number[]): Promise<void> {
  const response = await api.post('/logs/mark-as-seen', { log_ids: ids });
  return response?.data;
}
