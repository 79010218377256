
import { computed, defineComponent, onMounted, onUnmounted } from 'vue';

export default defineComponent({
  props: {
    position: {
      type: String,
      default: 'right',
    },
  },
  emits: ['close'],
  setup(props, { emit, slots }) {
    const hasFooter = computed(() => !!slots.footer);
    const positionClass = computed(() => `fixed-${props.position}`);

    const closePane = () => {
      emit('close');
    };

    const escapeHandler = (event: any) => {
      if (event.keyCode === 27) {
        closePane();
      }
    };

    onMounted(() => document.addEventListener('keydown', escapeHandler));
    onUnmounted(() => document.removeEventListener('keydown', escapeHandler));

    return { hasFooter, positionClass, closePane };
  },
});
