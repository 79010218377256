
import { defineComponent } from 'vue';

import Button from '@components/Button.vue';
import FormItemWrapper from '@components/form/FormItemWrapper.vue';
import FormPassword from '@components/form/FormPassword.vue';
import Modal from '@components/Modal.vue';

import { useForm } from '@modules/kernel/hooks';

export default defineComponent({
  components: { Modal, Button, FormItemWrapper, FormPassword },
  props: {
    bodyTranslationKey: {
      type: String,
      required: true,
    },
    buttonTranslationKey: {
      type: String,
      required: false,
      default: 'global.confirm',
    },
    color: {
      type: String,
      default: 'primary',
    },
    askPassword: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const {
      form,
      errors,
      validate,
      isTouched: isFormTouched,
    } = useForm({
      password: {
        type: String,
        required: props.askPassword,
      },
    });

    const closeModal = () => {
      emit('close');
    };

    const confirm = async () => {
      const isFormValid = await validate();
      if (!isFormValid) return;

      emit('confirm', form.password);
    };

    return { errors, form, isFormTouched, closeModal, confirm };
  },
});
