import { Ref, ref } from 'vue';
import { ColorSampleOrderDto, getColorSampleOrder } from '../services';

export default function useColorSampleOrder() {
  const result: Ref<ColorSampleOrderDto | undefined> = ref();
  const active = ref(false);

  const run = async (id: number) => {
    active.value = true;

    result.value = await getColorSampleOrder(id);

    active.value = false;
  };

  return { result, active, run };
}
