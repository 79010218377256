import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar-nav bg-gradient-primary sidebar" }
const _hoisted_2 = { class: "sidebar-brand d-flex align-items-center justify-content-center" }
const _hoisted_3 = { class: "sidebar-brand-text mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarDivider = _resolveComponent("SidebarDivider")!
  const _component_SidebarItem = _resolveComponent("SidebarItem")!
  const _component_SidebarDropdown = _resolveComponent("SidebarDropdown")!

  return _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.applicationName), 1)
    ]),
    _createVNode(_component_SidebarDivider),
    (_ctx.isRouteAllowed('Dashboard'))
      ? (_openBlock(), _createBlock(_component_SidebarItem, {
          key: 0,
          title: _ctx.$t('global.dashboard'),
          icon: "tachometer-alt",
          link: "Dashboard"
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (
        _ctx.isRouteAllowed('ClosetCategoryOverview') ||
        _ctx.isRouteAllowed('ClosetStructureOverview') ||
        _ctx.isRouteAllowed('ArticleOverview') ||
        _ctx.isRouteAllowed('ArticlePartOverview') ||
        _ctx.isRouteAllowed('ColorOverview') ||
        _ctx.isRouteAllowed('ColorCategoryOverview') ||
        _ctx.isRouteAllowed('ClosetGripOverview')
      )
      ? (_openBlock(), _createBlock(_component_SidebarDivider, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('ClosetCategoryOverview') || _ctx.isRouteAllowed('ClosetStructureOverview'))
      ? (_openBlock(), _createBlock(_component_SidebarDropdown, {
          key: 2,
          title: _ctx.$t('closet.title'),
          icon: "table",
          children: [
        {
          key: 'closetStructure.title.sidebar',
          link: 'ClosetStructureOverview',
          visible: _ctx.isRouteAllowed('ClosetStructureOverview'),
        },
        {
          key: 'closetCategory.title.sidebar',
          link: 'ClosetCategoryOverview',
          visible: _ctx.isRouteAllowed('ClosetCategoryOverview'),
        },
      ]
        }, null, 8, ["title", "children"]))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('ArticleOverview') || _ctx.isRouteAllowed('ArticlePartOverview'))
      ? (_openBlock(), _createBlock(_component_SidebarDropdown, {
          key: 3,
          title: _ctx.$t('article.title'),
          icon: "database",
          children: [
        { key: 'article.title', link: 'ArticleOverview', visible: _ctx.isRouteAllowed('ArticleOverview') },
        {
          key: 'articlePart.title',
          link: 'ArticlePartOverview',
          visible: _ctx.isRouteAllowed('ArticlePartOverview'),
        },
      ]
        }, null, 8, ["title", "children"]))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('ColorOverview') || _ctx.isRouteAllowed('ColorCategoryOverview'))
      ? (_openBlock(), _createBlock(_component_SidebarDropdown, {
          key: 4,
          title: _ctx.$t('color.title'),
          icon: "palette",
          children: [
        { key: 'color.title', link: 'ColorOverview', visible: _ctx.isRouteAllowed('ColorOverview') },
        {
          key: 'colorCategory.title.sidebar',
          link: 'ColorCategoryOverview',
          visible: _ctx.isRouteAllowed('ColorCategoryOverview'),
        },
      ]
        }, null, 8, ["title", "children"]))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('ClosetGripOverview'))
      ? (_openBlock(), _createBlock(_component_SidebarDropdown, {
          key: 5,
          title: _ctx.$t('accessories.title'),
          icon: "gifts",
          children: [
        { key: 'closetGrip.title', link: 'ClosetGripOverview', visible: _ctx.isRouteAllowed('ClosetGripOverview') },
      ]
        }, null, 8, ["title", "children"]))
      : _createCommentVNode("", true),
    (
        _ctx.isRouteAllowed('CustomerOverview') ||
        _ctx.isRouteAllowed('ColorSampleOrderOverview') ||
        _ctx.isRouteAllowed('OrderOverview')
      )
      ? (_openBlock(), _createBlock(_component_SidebarDivider, { key: 6 }))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('CustomerOverview'))
      ? (_openBlock(), _createBlock(_component_SidebarItem, {
          key: 7,
          title: _ctx.$t('customer.title'),
          icon: "user-tag",
          link: "CustomerOverview"
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('ColorSampleOrderOverview'))
      ? (_openBlock(), _createBlock(_component_SidebarDropdown, {
          key: 8,
          title: _ctx.$t('order.title'),
          icon: "shopping-cart",
          children: [
        {
          key: 'colorSampleOrder.title.sidebar',
          link: 'ColorSampleOrderOverview',
          visible: _ctx.isRouteAllowed('ColorSampleOrderOverview'),
        },
        {
          key: 'order.title',
          link: 'OrderOverview',
          visible: _ctx.isRouteAllowed('OrderOverview'),
        },
      ]
        }, null, 8, ["title", "children"]))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('UserOverview') || _ctx.isRouteAllowed('WebsiteTexts') || _ctx.isRouteAllowed('Logs'))
      ? (_openBlock(), _createBlock(_component_SidebarDivider, { key: 9 }))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('UserOverview'))
      ? (_openBlock(), _createBlock(_component_SidebarItem, {
          key: 10,
          title: _ctx.$t('global.users'),
          icon: "user",
          link: "UserOverview"
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('WebsiteTexts') || _ctx.isRouteAllowed('WebsiteImages'))
      ? (_openBlock(), _createBlock(_component_SidebarDropdown, {
          key: 11,
          title: _ctx.$t('global.tools'),
          icon: "tools",
          children: [
        { key: 'websiteText.title', link: 'WebsiteTexts', visible: _ctx.isRouteAllowed('WebsiteTexts') },
        { key: 'websiteImage.title', link: 'WebsiteImages', visible: _ctx.isRouteAllowed('WebsiteImages') },
      ]
        }, null, 8, ["title", "children"]))
      : _createCommentVNode("", true),
    (_ctx.isRouteAllowed('Logs'))
      ? (_openBlock(), _createBlock(_component_SidebarItem, {
          key: 12,
          title: _ctx.$t('global.logs'),
          icon: "clipboard-list",
          link: "Logs"
        }, null, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 512)), [
    [_vShow, _ctx.showSidebar]
  ])
}