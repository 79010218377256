import { Ref, ref } from 'vue';
import { ColorCategoryDto, getColorCategories } from '../services';

const result: Ref<ColorCategoryDto[]> = ref([]);
const active = ref(false);

export default function useColorCategories() {
  const run = async () => {
    active.value = true;

    result.value = await getColorCategories();

    active.value = false;
  };

  return { result, active, run };
}
