import { Language } from '@/i18n';

export class UpdateGeneralSettingsDto {
  language: Language;
  notificationEmail: string;

  constructor(dto: UpdateGeneralSettingsDto) {
    this.language = dto.language;
    this.notificationEmail = dto.notificationEmail;
  }
}
