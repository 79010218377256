export class UpdateClosetSettingsDto {
  maxAmountOfSamples: number;
  singleMinWidth: number;
  singleMaxWidth: number;
  singleDefaultWidth: number;
  singleMaxHeight: number;
  singleMinHeight: number;
  singleDefaultHeight: number;
  singleMinDepth: number;
  singleMaxDepth: number;
  singleDefaultDepth: number;
  doubleMinWidth: number;
  doubleMaxWidth: number;
  doubleDefaultWidth: number;
  doubleMinHeight: number;
  doubleMaxHeight: number;
  doubleDefaultHeight: number;
  doubleMinDepth: number;
  doubleMaxDepth: number;
  doubleDefaultDepth: number;
  defaultExtraPrice: number;

  constructor(dto: UpdateClosetSettingsDto) {
    this.maxAmountOfSamples = dto.maxAmountOfSamples;
    this.singleMinWidth = dto.singleMinWidth;
    this.singleMaxWidth = dto.singleMaxWidth;
    this.singleDefaultWidth = dto.singleDefaultWidth;
    this.singleMaxHeight = dto.singleMaxHeight;
    this.singleMinHeight = dto.singleMinHeight;
    this.singleDefaultHeight = dto.singleDefaultHeight;
    this.singleMinDepth = dto.singleMinDepth;
    this.singleMaxDepth = dto.singleMaxDepth;
    this.singleDefaultDepth = dto.singleDefaultDepth;
    this.doubleMinWidth = dto.doubleMinWidth;
    this.doubleMaxWidth = dto.doubleMaxWidth;
    this.doubleDefaultWidth = dto.doubleDefaultWidth;
    this.doubleMinHeight = dto.doubleMinHeight;
    this.doubleMaxHeight = dto.doubleMaxHeight;
    this.doubleDefaultHeight = dto.doubleDefaultHeight;
    this.doubleMinDepth = dto.doubleMinDepth;
    this.doubleMaxDepth = dto.doubleMaxDepth;
    this.doubleDefaultDepth = dto.doubleDefaultDepth;
    this.defaultExtraPrice = dto.defaultExtraPrice;
  }
}
