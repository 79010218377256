import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageHeader, null, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('websiteText.title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Tabs, { tabs: _ctx.tabs }, null, 8, ["tabs"])
  ], 64))
}