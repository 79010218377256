import api from '@/custom/api';
import { ImageDto } from './types';

type UploadImageCriteria = {
  height?: number;
  width?: number;
  maintainRatio?: boolean;
};

export async function uploadImage(image: Blob, criteria?: UploadImageCriteria): Promise<ImageDto> {
  // use FormData because of image
  const formData = new FormData();
  formData.append('image', image);

  if (criteria?.height) {
    formData.append('height', criteria.height.toString());
  }
  if (criteria?.width) {
    formData.append('width', criteria.width.toString());
  }
  if (criteria?.maintainRatio) {
    formData.append('maintain_ratio', criteria.maintainRatio.toString());
  }

  const response = await api.post('/images', formData);
  return new ImageDto(response.data);
}
