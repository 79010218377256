
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';

import LoadingSpinner from '@components/LoadingSpinner.vue';

export default defineComponent({
  components: { LoadingSpinner },
  props: {
    size: {
      type: String as PropType<'sm' | 'md' | 'lg' | 'xl' | 'xxl'>,
      default: 'lg',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    isFormTouched: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props, { emit, slots }) {
    const hasFooter = computed(() => !!slots.footer);
    const sizeClass = computed(() => `modal-${props.size}`);

    const pulseAnimation = ref(false);
    const pulseModal = () => {
      pulseAnimation.value = true;
      setTimeout(() => {
        pulseAnimation.value = false;
      }, 250);
    };

    const closeModal = () => {
      emit('close');
    };

    const handleEscape = (event: any) => {
      if (event.keyCode === 27) {
        handleCloseModal(false, 'esc');
      }
    };

    const isPreparingForClose = ref(false);
    const enablePreClose = () => {
      isPreparingForClose.value = true;
    };
    const disablePreClose = () => {
      isPreparingForClose.value = false;
    };

    const handleCloseModal = (validate: boolean, source: 'outside-click' | 'esc' | 'button') => {
      if (validate && !isPreparingForClose.value) {
        return;
      }

      if (props.fixed || (source === 'outside-click' && props.isFormTouched)) {
        pulseModal();
        return;
      }

      closeModal();
    };

    onMounted(() => document.addEventListener('keydown', handleEscape));
    onUnmounted(() => document.removeEventListener('keydown', handleEscape));

    return {
      hasFooter,
      pulseAnimation,
      sizeClass,

      disablePreClose,
      enablePreClose,
      handleCloseModal,
    };
  },
});
