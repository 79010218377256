import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { ArticlePartDto, CreateArticlePartDto, UpdateArticlePartDto } from './types';

export async function getArticleParts(
  search?: string,
  paginationFilters?: PaginationFilters
): Promise<PaginatedResponse<ArticlePartDto>> {
  const response = await api.get('/article-parts', { params: { ...paginationFilters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new ArticlePartDto(item)),
  };
}

export async function getArticlePart(id: number): Promise<ArticlePartDto> {
  const response = await api.get(`/article-parts/${id}`);
  return new ArticlePartDto(response.data);
}

export async function saveArticlePart(id: number, form: UpdateArticlePartDto): Promise<void> {
  const response = await api.put(`/article-parts/${id}`, new UpdateArticlePartDto(form));
  return response?.data;
}

export async function createArticlePart(form: CreateArticlePartDto): Promise<void> {
  const response = await api.post('/article-parts', new CreateArticlePartDto(form));
  return response?.data;
}

export async function deleteArticlePart(id: number): Promise<void> {
  const response = await api.delete(`/article-parts/${id}`);
  return response?.data;
}
