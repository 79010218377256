import api from '@/custom/api';
import { AuthResponseDto } from '@modules/kernel/services';

export async function impersonateUser(userId: number): Promise<AuthResponseDto> {
  const response = await api.post('/auth/impersonate', { userId });

  if (response?.data) {
    // remove old token
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');

    // set new token
    if (localStorage.getItem('user')) {
      localStorage.setItem('user', response.data.token);
    } else {
      sessionStorage.setItem('user', response.data.token);
    }
  }

  return new AuthResponseDto(response.data);
}
