
import { computed, defineComponent, Ref, ref, shallowRef } from 'vue';
import { debounce } from 'vue-debounce';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import ContentCard from '@components/layout/ContentCard.vue';
import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';
import PageHeader from '@components/layout/PageHeader.vue';

import Button from '@components/Button.vue';
import Modal from '@components/Modal.vue';
import Table, { Column, ColumnType } from '@components/table/Table.vue';

import { DateFormat, dateToString } from '@utils/date.util';

import { useModal } from '@modules/kernel/hooks';
import { createAsyncProcess } from '@modules/kernel/utils';

import JsonObject from './components/JsonObject.vue';
import NewSnippet from './components/NewSnippet.vue';
import TypeSnippet from './components/TypeSnippet.vue';
import useLogs from './hooks/use-logs';
import { markAsSeen } from './services/log-service';
import { Log } from './types';

export default defineComponent({
  components: {
    PageHeader,
    GridRow,
    GridCol,
    ContentCard,
    Table,
    Modal,
    Button,
    JsonObject,
  },
  setup() {
    const { t } = useI18n();
    const toast = useToast();

    const { result: logs, active: isLoadingLogs, run: runGetLogs } = useLogs();
    const { run: runMarkAsSeen } = createAsyncProcess(markAsSeen);

    const newLogs = computed(() => logs.value.filter((log) => log.new));

    /**
     * LOGS TABLE
     */
    const columns: Ref<Column[]> = ref([
      {
        key: 'new',
        type: ColumnType.Component,
        widthPixels: 35,
        component: shallowRef(NewSnippet),
        visible: () => !!logs.value.some((log) => log.new),
      },
      {
        key: 'dateAdded',
        translationKey: 'global.date',
        type: ColumnType.Date,
        format: DateFormat.IsoWithoutT,
      },
      {
        key: 'userName',
        translationKey: 'global.user',
        type: ColumnType.String,
      },
      {
        key: 'type',
        translationKey: 'global.type',
        type: ColumnType.Component,
        component: shallowRef(TypeSnippet),
      },
      {
        key: 'path',
        translationKey: 'logs.action',
        type: ColumnType.String,
      },
      {
        key: 'description',
        translationKey: 'global.description',
        type: ColumnType.String,
      },
      {
        key: 'id',
        type: ColumnType.Actions,
        widthPercentage: 10,
        actions: [
          {
            event: 'showDetails',
            icon: 'eye',
            visible: (record: Log) => record.objectBefore || record.objectAfter,
          },
        ],
      },
    ]);

    const submitMarkAsSeen = async () => {
      if (!newLogs.value.length) return;
      await runMarkAsSeen(newLogs.value.map((log) => log.id));
    };
    const debouncedSubmitMarkAsSeen = debounce(submitMarkAsSeen, 2000);

    const getLogs = async (data?: { search: string }) => {
      try {
        await runGetLogs(data?.search);
      } catch (error: any) {
        toast.error(error.message);
      }
      debouncedSubmitMarkAsSeen();
    };
    getLogs();

    /**
     * LOG DETAILS MODAL
     */
    const selectedLog = ref<Log | null>();
    const logModalbody = computed(() =>
      t('logs.modalBody', [
        selectedLog.value?.userName,
        dateToString(selectedLog.value?.dateAdded, DateFormat.IsoWithoutT),
      ])
    );

    const { visible: isLogModalVisible, hide: hideLogModal } = useModal();
    const showLogModal = (log: Log) => {
      isLogModalVisible.value = true;
      selectedLog.value = log;
    };

    return {
      columns,
      isLoadingLogs,
      isLogModalVisible,
      logModalbody,
      logs,
      selectedLog,
      getLogs,
      hideLogModal,
      showLogModal,
    };
  },
});
