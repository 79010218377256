import api from '@/custom/api';
import { PaginatedResponse, PaginationFilters } from '@modules/kernel/types';
import { ClosetGripDto, CreateClosetGripDto, UpdateClosetGripDto } from './types';

export async function getClosetGrips(
  search?: string,
  paginationFilters?: PaginationFilters
): Promise<PaginatedResponse<ClosetGripDto>> {
  const response = await api.get('/closet-grips', { params: { ...paginationFilters, search } });
  return {
    ...response.data,
    items: response.data.items.map((item: any) => new ClosetGripDto(item)),
  };
}

export async function getClosetGrip(id: number): Promise<ClosetGripDto> {
  const response = await api.get(`/closet-grips/${id}`);
  return new ClosetGripDto(response.data);
}

export async function saveClosetGrip(id: number, form: UpdateClosetGripDto): Promise<void> {
  const response = await api.put(`/closet-grips/${id}`, new UpdateClosetGripDto(form));
  return response?.data;
}

export async function createClosetGrip(form: CreateClosetGripDto): Promise<void> {
  const response = await api.post('/closet-grips', new CreateClosetGripDto(form));
  return response?.data;
}

export async function deleteClosetGrip(id: number): Promise<void> {
  const response = await api.delete(`/closet-grips/${id}`);
  return response?.data;
}
