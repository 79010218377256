
import { defineComponent, Ref, ref, shallowRef, watch } from 'vue';
import { Tooltip } from 'floating-vue';
import { useToast } from 'vue-toastification';

import { RouteKeys } from '@/router';

import ContentCard from '@components/layout/ContentCard.vue';
import GridCol from '@components/layout/GridCol.vue';
import GridRow from '@components/layout/GridRow.vue';
import PageHeader from '@components/layout/PageHeader.vue';

import Button from '@components/Button.vue';
import Table, { Column, ColumnType } from '@components/table/Table.vue';

import { useModal } from '@modules/kernel/hooks';
import { PaginationFilters } from '@modules/kernel/types';

import UserStatusSnippet from './components/UserStatusSnippet.vue';
import useUsers from './hooks/use-users';
import AddUserModal from './AddUserModal.vue';
import { UserDto } from './services';

export default defineComponent({
  components: {
    PageHeader,
    GridRow,
    GridCol,
    ContentCard,
    Button,
    Table,
    Tooltip,
    AddUserModal,
  },
  setup() {
    const toast = useToast();

    const { result: users, metadata: pageMetadata, active: isLoadingUsers, run: runGetUsers } = useUsers();

    const tableInterface: Ref<any | undefined> = ref();

    const columns: Ref<Column[]> = ref([
      {
        key: 'name',
        translationKey: 'global.name',
        type: ColumnType.String,
      },
      {
        key: 'email',
        translationKey: 'global.email',
        type: ColumnType.String,
      },
      {
        key: 'status',
        translationKey: 'global.status',
        type: ColumnType.Component,
        component: shallowRef(UserStatusSnippet),
      },
      {
        key: 'id',
        type: ColumnType.Actions,
        widthPercentage: 10,
        actions: [
          {
            link: (row: UserDto) => ({ name: RouteKeys.UserDetails, params: { id: row.id } }),
            icon: 'eye',
          },
        ],
      },
    ]);

    const getUsers = async (data?: { search: string; paginationFilters?: PaginationFilters }) => {
      try {
        const paginationFilters = data?.paginationFilters ?? { page: 1, length: 25 };
        await runGetUsers(data?.search, paginationFilters);
      } catch (error: any) {
        toast.error(error.message);
      }
    };
    getUsers();

    /**
     * Add modal
     */
    const { visible: isAddUserModalVisible, show: showAddUserModal, hide: hideAddUserModal } = useModal();

    watch(isAddUserModalVisible, async (current, previous) => {
      if (previous === true && current === false && tableInterface) {
        // modal is closed
        tableInterface.value.sendFilters();
      }
    });

    /**
     * Table interface
     */
    const getTableInterface = (childInterface: any) => {
      tableInterface.value = childInterface;
    };

    return {
      columns,
      isAddUserModalVisible,
      isLoadingUsers,
      pageMetadata,
      users,

      getTableInterface,
      getUsers,
      hideAddUserModal,
      showAddUserModal,
    };
  },
});
