import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormUpload = _resolveComponent("FormUpload")!
  const _component_FormItemWrapper = _resolveComponent("FormItemWrapper")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "rounded-circle mb-2",
        src: _ctx.imageUrl,
        width: "150",
        height: "150"
      }, null, 8, _hoisted_2)
    ]),
    (!_ctx.readonly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
          }, [
            _createVNode(_component_FormItemWrapper, {
              errors: _ctx.errors.image
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FormUpload, {
                  modelValue: _ctx.form.image,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.image) = $event)),
                  errors: _ctx.errors.image,
                  accept: "image/png, image/jpeg"
                }, null, 8, ["modelValue", "errors"])
              ]),
              _: 1
            }, 8, ["errors"]),
            _createVNode(_component_Button, {
              block: "",
              type: "submit",
              disabled: _ctx.isUploadingImage || _ctx.isChangingAvatar
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('global.upload')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ], 32)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}