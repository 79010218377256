import { parseDate } from '@utils/date.util';

export class NotificationGroupDto {
  newCount: number;
  items: NotificationDto[];

  constructor({ items, ...rest }: NotificationGroupDto) {
    this.items = items.map((item) => new NotificationDto(item));
    Object.assign(this, rest);
  }
}

class NotificationDto {
  id: number;
  title: string;
  message: string;
  icon: string;
  color: string;
  seen: boolean;
  dateAdded: Date;

  constructor({ dateAdded, ...rest }: NotificationDto) {
    this.dateAdded = parseDate(dateAdded)!;
    Object.assign(this, rest);
  }
}
